.best {
  background-image: url('/images/ico_best.svg');
  background-repeat: no-repeat;
  background-position-x: 90px;
  background-position-y: 9px;
}

.onePerson {
  margin-top: 40px;
  .titleImg {
    display: block;
  }
  .def {
    padding: 40px;
    width: 100%;
    border: 1px solid #00865d;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: rgba(0, 134, 93, 0.05);
    .term {
      margin-top: 21px;
      color: #2e2e2e;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.96px;
      &:before {
        content: '';
        display: inline-block;
        position: relative;
        top: -3px;
        margin-right: 6px;
        width: 6px;
        height: 6px;
        background-color: #2e2e2e;
        border-radius: 50%;
      }
      &:first-child {
        margin-top: 0;
      }
    }
    .des {
      margin-top: 10px;
      color: #2e2e2e;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.56;
      letter-spacing: -0.96px;
      .strong {
        color: #00865d;
        font-weight: 700;
      }
      .lineThrough {
        text-decoration: line-through;
      }
      .badge {
        position: relative;
        margin-top: 12px;
        width: 480px;
        height: 214px;
        background-color: #fff;
        .badgeImg {
          display: inline-block;
          position: absolute;
          top: 33px;
          left: 46px;
        }
        &:after {
          content: '해당 질문의 답변에 [네] 체크';
          display: inline-block;
          position: absolute;
          bottom: 23px;
          right: 52px;
          color: #fc526e;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: -0.64px;
          z-index: 1;
        }
      }
    }
  }
}
.infoImg {
  background-color: #ecf0f8;
  padding: 8px;
}
.sizeExplain {
  display: inline-block;
  color: #777c86;
  font-size: 18px;
}
