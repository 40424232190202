.explainBox {
  margin-top: 6px;
  .title {
    position: relative;
    padding-left: 12px;
    color: #2e2e2e;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.41;
    letter-spacing: -0.34px;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 12px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #2e2e2e;
    }
  }
  .list {
    li {
      position: relative;
      padding-left: 8px;
      margin-top: 4px;
      color: #585a5f;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: -0.74px;
      &:before {
        content: '-';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
