.radioImportant {
  font-weight: 500;
}
.radioTag {
  display: inline-block;
  position: absolute;
  top: 0;
  right: -366px;
  padding: 5px 0;
  width: 60px;
  height: 32px;
  background-color: #006ac3;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  letter-spacing: -0.48px;
  text-align: center;
}
