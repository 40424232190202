.table {
  width: 100%;
  th,
  td {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.88px;
  }
  .thead,
  .error {
    th {
      padding: 15px 0 16px;
      background-color: #f5f5f5;
      text-align: right;
      &:nth-child(1) {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
      &:nth-child(2) {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
    .investment {
      padding-right: 16px;
    }
    .investmentPercent {
      padding-right: 42px;
    }
  }
  .error {
    th {
      background-color: rgba(235, 77, 89, 0.2);
    }
  }
  .tbody,
  .tfoot {
    tr {
      td {
        padding: 20px 0;
        &:nth-child(1) {
          vertical-align: top;
          padding-left: 20px;
          padding-top: 26px;
        }
        &:nth-child(2) {
          padding: 10px 0;
          text-align: right;
        }
        &:nth-child(3) {
          vertical-align: top;
          padding-right: 42px;
          padding-top: 26px;
          text-align: right;
        }
      }
    }
    .companyInvestment {
      padding-right: 16px;
      .sum {
        color: #999fac;
      }
      .numToKorean {
        margin-top: 7px;
        color: #999fac;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
  .tbody {
    tr {
      &:first-child {
        td {
          &:nth-child(1) {
            padding-top: 36px;
          }
          &:nth-child(2) {
            padding-top: 20px;
          }
          &:nth-child(3) {
            padding-top: 36px;
          }
        }
      }
      &:last-child {
        td {
          &:nth-child(2) {
            padding-bottom: 20px;
          }
        }
      }
    }
  }
  .tfoot {
    border-top: 1px solid #c6cede;
    tr {
      td {
        color: #006ac3;
        &:nth-child(2) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
  }
}
