@import '@pages/ModalContent/ModalContent.module.scss';

.Modal {
  .title {
    width: 383px;
  }
  .modalContent {
    margin-top: 22px;
    width: 437px;
    letter-spacing: -0.8px;
  }
}
