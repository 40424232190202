@charset "utf-8";
/*  
* @ RESET CSS
* -------------------------------
*/
html,
body,
div,
p,
span,
strong,
b,
em,
iframe,
pre,
h1,
h2,
h3,
h4,
h5,
h6,
img,
dl,
dt,
dd,
button,
fieldset,
form,
legend,
label,
table,
caption,
thead,
tbody,
tfoot,
tr,
th,
td,
ul,
ol,
li,
a,
input,
select,
textarea {
  margin: 0;
  padding: 0;
  border: 0 none;
}

body {
  font-family: 'Noto Sans KR', MalgunGothic, '맑은 고딕', '돋움', Dotum, AppleGothic, Sans-serif, Arial;
  font-size: 12px;
  line-height: 1;
  color: #000;
  background-color: #eff0f2;
  overflow-y: scroll;
}
button {
  cursor: pointer;
}

ul,
ol,
li {
  list-style: none;
}
em,
address {
  font-style: normal;
}
img {
  border: 0 none;
  font-size: 0;
  line-height: 0;
}
sup {
  position: relative;
  top: 2px;
  font-size: 11px;
  line-height: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption {
  overflow: hidden;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}
th,
td {
  vertical-align: middle; /* white-space: nowrap */
}

a {
  color: #000;
  text-decoration: none;
  line-height: 1;
}
a:hover,
a:focus,
a:active {
  text-decoration: underline;
}
a:visited {
  color: #000;
}
*,
html {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: 'Noto Sans KR', MalgunGothic, '맑은 고딕', '돋움', Dotum, AppleGothic, Sans-serif, Arial;
  outline: none;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
