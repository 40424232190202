.estimate {
  .finEstimate {
    display: flex;
    justify-content: space-between;
    .estimatePrice {
      margin-top: 10px;
      .priceTitle {
        color: #000;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: -0.96px;
        .bold {
          color: #006ac3;
        }
      }
      .price {
        margin-top: 8px;
        color: #006ac3;
        font-size: 70px;
        font-weight: 500;
        letter-spacing: -1.75px;
        .won {
          font-size: 30px;
        }
      }
    }
  }
  .estimateInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 33px;
    border-top: 1px solid #c6cede;
    border-bottom: 1px solid #c6cede;
    .infoWrap {
      margin: 30px 0;
      padding-left: 15px;
      border-left: 1px solid #c7cede;
      &:first-child {
        padding-left: 0;
        border-left: none;
      }
      .infoTitle {
        display: inline-block;
        color: #777c86;
        font-size: 17px;
        font-weight: 500;
      }
      .info {
        margin-top: 7px;
        color: #000;
        font-size: 28px;
        font-weight: 500;
        .explain {
          position: relative;
          top: -2px;
          font-size: 18px;
        }
      }
    }
  }
}
.estimateExplain {
  display: flex;
  justify-content: space-between;
  &:nth-child(2) {
    margin-top: 10px;
  }
  .explain {
    color: #555;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.72px;
    line-height: 1.5;
  }
  .hyper {
    position: relative;
    top: 5px;
  }
}
.procedureWrap {
  margin-top: -15px;
  .procedureTitle {
    color: #000;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -0.7px;
  }
  .procedure {
    margin-top: 26px;
    width: 100%;
    .procedureExplain {
      color: #2e2e2e;
      font-size: 22px;
      font-weight: 300;
      letter-spacing: -0.88px;
      line-height: 1.55;
      .bold {
        color: #006ac3;
        font-weight: 500;
      }
    }
    .procedureStep {
      display: inline-flex;
      flex-wrap: wrap;
      margin-top: 4px;
      .stepBox {
        display: inline-block;
        position: relative;
        margin-top: 20px;
        margin-left: 50px;
        &:nth-child(1),
        &:nth-child(5) {
          margin-left: 0;
        }
        padding: 25px;
        width: 250px;
        height: 220px;
        border-radius: 6px;
        border: 1px solid #d3dae2;
        background-color: #fff;
        color: #555;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: -1.2px;

        .stepContent {
          margin-top: 24px;
        }
        &.helpme {
          border: none;
          background-color: #006ac3;
          color: #fff;
          .stepContent {
            font-size: 20px;
            .bold {
              color: #ff9d00;
            }
          }
        }
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 110px;
          left: -32px;
          width: 10px;
          height: 20px;
          background-image: url('/images/arrow.png');
          background-size: cover;
          background-repeat: no-repeat;
        }
        &:nth-child(1):before,
        &:nth-child(5):before {
          content: none;
        }
      }
      .workBox {
        position: relative;
        margin-top: 20px;
        margin-left: 30px;
        width: 250px;
        height: 220px;
        color: #555;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: -0.84px;
        line-height: 1.66;
        .work {
          position: absolute;
          bottom: 20px;
        }
        .customWork {
          &:before {
            content: '';
            display: inline-block;
            margin-right: 7px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: #006ac3;
          }
        }
        .helpmeWork {
          &:before {
            content: '';
            display: inline-block;
            margin-right: 7px;
            width: 9px;
            height: 9px;
            border: 1px solid #777c86;
            border-radius: 50%;
            background-color: #fff;
          }
        }
      }
    }
    .procedureGuidance {
      position: relative;
      margin-top: 66px;
      .unOrder {
        li {
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 12px;
            left: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #2e2e2e;
          }
          position: relative;
          padding-left: 17px;
          margin-top: 14px;
          &:first-child {
            margin-top: 0;
          }
          color: #222;
          font-size: 20px;
          font-weight: 300;
          line-height: 1.45;
          letter-spacing: -0.5px;
          .bold {
            color: #006ac3;
            font-weight: 500;
          }
        }
      }
      .supply {
        margin-top: 36px;
        padding: 30px 40px;
        width: 100%;
        background-color: #f7f7f7;
        border-radius: 4px;
        color: #000;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -1.08px;
        .order {
          margin-top: 20px;
          li {
            color: #222;
            font-size: 20px;
            font-weight: 300;
            line-height: 1.7;
            letter-spacing: -0.8px;
            .bold {
              color: #006ac3;
              font-weight: 500;
            }
            &:nth-child(1) {
              &:before {
                content: '1.';
                display: inline-block;
                margin-right: 5px;
              }
            }
            &:nth-child(2) {
              &:before {
                content: '2.';
                display: inline-block;
                margin-right: 5px;
              }
            }
          }
        }
      }
      .backgroundImg {
        position: absolute;
        bottom: 0;
        right: 51px;
        width: 211px;
        height: 383px;
        background-image: url('/images/Hyoyeon1.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}
