@import '@pages/ModalContent/ModalContent.module.scss';

.Modal {
  .title {
    width: 383px;
  }
  .modalContent {
    margin-top: 20px;
    width: 437px;
    .explainBox {
      display: flex;
      margin-top: 6px;
      font-size: 16px;
      color: #707070;
      .explinTitle {
        margin-right: 10px;
      }
      .box {
        margin-top: -4px;
        .explain {
          .arrow {
            position: relative;
            top: 5px;
          }
          .bold {
            color: #006ace;
          }
        }
      }
    }
  }
}
