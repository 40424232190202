.executiveCntTable {
  width: 789px;
  th {
    padding: 3px 10px;
    width: 130px;
    height: 26px;
    color: #000;
    font-size: 13px;
    letter-spacing: -0.13px;
    background-color: #f4f5f7;
    text-align: center;
    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }
  tr {
    margin-left: 20px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.13px;
    text-align: center;
    border-bottom: 1px solid #c6cbd5;
    td {
      padding: 5px 10px;
      width: 130px;
      border-top: 1px solid #c6cbd5;
      &:first-child {
        padding-left: 24px;
        text-align: center;
      }
      &:last-child {
        padding-right: 24px;
        text-align: center;
      }
    }
  }
}

.executiveTable {
  width: 789px;
  th {
    padding: 3px 10px;
    height: 26px;
    color: #000;
    font-size: 13px;
    letter-spacing: -0.13px;
    background-color: #f4f5f7;
    text-align: center;
    &:first-child {
      padding-left: 24px;
      text-align: left;
    }
    &:last-child {
      padding-right: 24px;
    }
  }
  tr {
    margin-left: 20px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.13px;
    text-align: center;
    &:first-child {
      td {
        border-top: none;
      }
    }
    &:last-child {
      td {
        border-bottom: 1px solid #c6cbd5;
      }
    }
    td {
      padding: 5px 10px;
      border-top: 1px solid #c6cbd5;
      &:first-child {
        padding-left: 24px;
        text-align: left;
      }
      &:last-child {
        padding-right: 24px;
      }
      .position {
        width: 50px;
        display: inline-block;
        vertical-align: top;
        position: relative;
        top: 2px;
      }
      .executiveInfo {
        display: inline-block;
        max-width: 100px;
        .koreanName {
          display: inline-block;
          margin-left: 6px;
        }
        .foreign {
          margin-left: 7px;
          color: #6f6f6f;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.17;
        }
      }
      .pageAlert {
        margin-top: 3px;
        color: #eb4d59;
        font-size: 12px;
      }
    }
  }
}
.notChange {
  td {
    color: rgb(126, 125, 125);
    font-weight: normal;
  }
  td button {
    color: rgb(126, 125, 125);
    font-weight: normal;
  }
}
