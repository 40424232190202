@import '@commonComponents/FocusBox/FocusBox.module.scss';

.table {
  position: relative;
  margin-top: 48px;
  font-size: 20px;
  letter-spacing: -0.5px;
  &:first-child {
    margin-top: 0;
  }
  .secretBtnArea {
    position: absolute;
    top: -3px;
    left: 165px;
    .secretBtn {
      padding: 0 5px;
      border: 2px solid #006ac3;
      color: #006ac3;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.9px;
      background-color: #fff;
      &:active {
        position: relative;
        left: 1px;
        top: 1px;
      }
    }
  }
  .reviseBtn {
    position: absolute;
    right: 0;
    top: -8px;
    padding: 1px 18px 2px;
    border: 1px solid #777c86;
    color: #777c86;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -0.17px;
    background-color: #fff;
    &:active {
      right: -1px;
      top: -8px;
    }
  }
  .title {
    padding-bottom: 9px;
    color: #2e2e2e;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -0.55px;
    border-bottom: 4px solid #2e2e2e;
  }
  .info {
    .row {
      display: flex;
      margin-top: 19px;
      .dName {
        width: 166px;
        flex-shrink: 0;
        color: #555;
        font-weight: 300;
        letter-spacing: -0.2px;
      }
      .data {
        color: #000;
        font-weight: 500;
        .shareholder,
        .executive {
          display: flex;
          padding: 13px 0;
          width: 840px;
          border-top: 1px solid #c6cbd5;
          line-height: 1.6;
          &:first-child {
            padding-top: 0;
            margin-top: 0;
            border: none;
          }
          .shareholderPosition {
            position: relative;
            top: -6px;
            flex-shrink: 0;
          }
          .executivePosition {
            min-width: 71px;
            line-height: 1.1;
          }
          .shareholderInfo,
          .executiveInfo {
            position: relative;
            top: -6px;
            margin-left: 30px;
            .person {
              span {
                &:before {
                  content: '';
                  display: inline-block;
                  position: relative;
                  top: 3px;
                  margin: 0 14px;
                  width: 2px;
                  height: 20px;
                  border-radius: 50px;
                  background-color: #999fac;
                }
                &:first-child:before {
                  content: none;
                }
                &:nth-child(n + 3):nth-child(-n + 4) {
                  font-weight: lighter;
                }
              }
              .personType {
                position: relative;
                top: -2px;
                display: inline-block;
                padding: 0 5px;
                margin-left: 13px;
                background-color: #006ac3;
                color: #fff;
                font-size: 16px;
                font-weight: 500;
              }
              .resignation {
                position: relative;
                top: -2px;
                color: #006ac3;
                font-size: 16px;
              }
            }
            .address {
              margin-top: 4px;
              font-weight: lighter;
            }
            .positionInfo {
              color: #999fac;
              font-size: 18px;
              font-weight: 300;
              .star {
                position: relative;
                top: 4px;
              }
            }
            .representativeInfo {
              display: inline-flex;
              font-weight: lighter;
              .representativeTitle {
                margin-right: 14px;
              }
            }
          }
        }
        &.businessPurpose {
          margin-left: -20px;
          margin-top: -10px;
          font-weight: normal;
          .purpose {
            display: inline-block;
            margin-left: 20px;
            line-height: 1.75;
            &.lineThrough {
              font-weight: 500;
              text-decoration: line-through;
            }
          }
        }
        .stampData {
          margin-top: 8px;
        }
      }
    }
  }
}
.lightTable {
  width: 100%;
  color: #555;
  font-weight: 300;
  th {
    height: 38px;
    background-color: #f6f5f5;
    font-size: 17px;
    font-weight: 400;
    padding: 0 15px;
  }
  td {
    font-size: 15px;
    line-height: 1.5;
    padding: 0 15px;
    .name {
      font-size: 19px;
      font-weight: 500;
      .englishName {
        font-size: 13px;
      }
    }
    &.bottom {
      border-bottom: 1px solid #d8d8d8;
      padding-bottom: 11px;
    }
    &.top {
      padding-top: 11px;
    }
    vertical-align: top;
  }
  tr.bottom {
    td {
      border-bottom: 1px solid #d8d8d8;
      padding-bottom: 11px;
    }
  }
  tr.top {
    td {
      padding-top: 15px;
    }
  }
}
.finalSubmitBtn {
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
  .explain {
    color: #555;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.8;
    letter-spacing: -0.72px;
    .infoList {
      li {
        position: relative;
        margin-left: 30px;
        color: #777c86;
        font-size: 14px;
        line-height: 1.5;
        &:before {
          content: '-';
          display: inline-block;
          position: absolute;
          left: -10px;
          top: 0;
        }
        .balanceCertificateList {
          li {
            margin-left: 0;
            &:before {
              display: inline-block;
              content: '';
            }
          }
        }
      }
    }
  }
  .sumbitBtn {
    position: relative;
    top: 8px;
    z-index: 4500;
  }
}
.questionBold {
  color: #eb4d59;
}
.importantExplain,
.importantExplainClose {
  position: relative;
  color: #fff;
  font-weight: 500;
  font-size: 28px;
  letter-spacing: -1.7px;
  &.importantExplain {
    top: 24px;
  }
  &.importantExplainClose {
    top: 32px;
  }
}
