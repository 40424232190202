.changeReflectionBtnArea {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .causeBtnBox {
    font-size: 13px;
    letter-spacing: -0.5px;
  }
  .changeReflactionBtnBox {
    .changeReflectionBtn {
      padding: 0 30px;
      border: 1px solid #006ac3;
      background-color: #fff;
      color: #006ac3;
      font-size: 15px;
      line-height: 2.08;
      letter-spacing: -0.65px;
    }
  }
}
.table {
  margin-top: 20px;
  font-size: 13px;
  letter-spacing: -0.5px;
  &:first-child {
    margin-top: 0;
  }
  .btnArea {
    position: relative;
    .btnBox {
      position: absolute;
      right: 0;
      bottom: -15px;
      .managerModeBtn,
      .estimateBtn {
        padding: 0 19px;
        height: 28px;
        font-size: 13px;
        letter-spacing: -0.65px;
        border: none;
        &.managerModeBtn {
          background-color: #006ac3;
          color: #fff;
        }
        &.estimateBtn {
          margin-left: 12px;
          border: 1px solid #006ac3;
          background-color: #fff;
          color: #006ac3;
        }
      }
    }
  }
  .title {
    padding-bottom: 9px;
    color: #2e2e2e;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.55px;
    border-bottom: 1px solid #2e2e2e;
    .titleAlert {
      display: inline-block;
      margin-left: 12px;
      color: #eb4d59;
      font-size: 13px;
    }
  }
  .info {
    .row {
      display: flex;
      align-items: baseline;
      min-height: 19px;
      margin-top: 6px;
      &:first-child {
        margin-top: 10px;
      }
      .dName {
        position: relative;
        width: 140px;
        flex-shrink: 0;
        color: #555;
        font-weight: 300;
        .reviseBtn {
          position: absolute;
          top: -1px;
          right: 25px;
          background-color: transparent;
        }
      }
      .data {
        font-weight: 500;
        &.businessPurpose {
          margin-left: -20px;
          margin-top: -10px;
          .purpose {
            display: inline-block;
            margin-left: 20px;
            line-height: 1.75;
            &.lineThrough {
              text-decoration: line-through;
            }
          }
        }
        .newAddressBox {
          margin-top: 2px;
          &:first-child {
            margin-top: 0;
          }
        }
        .addressCopyBtn {
          margin-left: 8px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
      .stockOptionCopyBtn {
        margin-left: 7px;
        font-size: 14px;
        &:first-child {
          margin-left: 8px;
        }
      }
      .addressCopyBracket {
        display: inline-block;
        margin-left: 7px;
      }
      .addressCopyAllBtn {
        margin-left: 7px;
        .copyIcon {
          position: relative;
          top: 3px;
        }
      }
    }
  }
}

.lightTable {
  width: 100%;
  color: #555;
  font-weight: 300;
  th {
    height: 38px;
    background-color: #f6f5f5;
    font-size: 14px;
    font-weight: 400;
    padding: 0 12px;
    line-height: 1.12;
  }
  td {
    font-size: 12px;
    line-height: 1.5;
    padding: 0 12px;
    &.bottom {
      border-bottom: 1px solid #d8d8d8;
      padding-bottom: 11px;
    }
    &.top {
      padding-top: 11px;
    }
    .addressCopyBtn {
      margin-left: 8px;
      &:first-child {
        margin-left: 0;
      }
    }
    .addressCopyBracket {
      display: inline-block;
      margin-left: 7px;
    }
    .addressCopyAllBtn {
      margin-left: 7px;
      .copyIcon {
        position: relative;
        top: 3px;
      }
    }
  }
  tr.bottom {
    td {
      border-bottom: 1px solid #d8d8d8;
      padding-bottom: 12px;
    }
  }
  tr.top {
    td {
      padding-top: 12px;
    }
  }
}
.agendaArea {
  margin-top: 20px;
  .agendaTitle {
    padding-bottom: 9px;
    color: #2e2e2e;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.55px;
    border-bottom: 1px solid #2e2e2e;
  }
  ul {
    li {
      margin-top: 10px;
      color: #000;
      font-size: 13px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.13px;
    }
  }
  .explainBox {
    margin-top: 10px;
    .explain {
      position: relative;
      padding-left: 15px;
      color: #555;
      font-size: 13px;
      font-weight: normal;
      line-height: 1.38;
      letter-spacing: -0.65px;
      &:before {
        content: '※';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
.standby,
.smartForm {
  color: #006ac3;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.6px;
}
