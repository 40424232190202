.checkBox {
  width: 22px !important;
  height: 22px !important;

  div {
    position: absolute;
    &:before {
      top: 0;
    }
  }
}
