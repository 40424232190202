.chairman {
  position: relative;
  .tag {
    position: absolute;
    top: 17px;
    right: 17px;
    width: 60px;
    height: 32px;
    padding: 7px 15px 5px;
    border-radius: 4px;
    background-color: #006ac3;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.48px;
    text-align: center;
  }
}
.table {
  width: 100%;
  thead {
    tr {
      th {
        background-color: #f6f5f5;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.48px;
        &:nth-child(1) {
          padding: 11px 4px 12px 12px;
          .check {
            div {
              &:before {
                top: 0;
              }
            }
          }
        }
        &:nth-child(2) {
          padding: 11px 24px 12px 40px;
          text-align: left;
        }
      }
    }
  }
  tbody {
    tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
      td {
        color: #000;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.54px;
        border-bottom: 1px solid #c6cede;
        &:nth-child(1) {
          padding: 17px 4px 16px 12px;
          text-align: center;
          .check {
            div {
              padding-left: 0;
              &:before {
                position: relative;
                top: 2px;
              }
            }
          }
        }
        &:nth-child(2) {
          padding: 17px 24px 16px 4px;
          .index {
            display: inline-block;
            margin-right: 3px;
            width: 30px;
          }
          .position {
            margin-left: 8px;
            color: #777c86;
            font-weight: 400;
          }
        }
      }
    }
  }
  tfoot {
    tr {
      td {
        padding-top: 12px;
        border-top: 2px solid #999fac;
        .checkState {
          display: flex;
          align-items: center;
          .lack {
            padding: 17px 30px;
            width: 172px;
            height: 58px;
            color: #eb4d59;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: -0.5px;
            background-color: #fdedee;
            border: 2px solid #eb4d59;
            border-radius: 2px;
            background-image: url('/images/ico_warning.svg');
            background-position: right 27px top 16px;
            background-repeat: no-repeat;
          }
          .enough {
            padding: 17px 30px;
            width: 172px;
            height: 58px;
            color: #0fac88;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: -0.5px;
            background-color: #e6f7f3;
            border: 2px solid #0fac88;
            border-radius: 2px;
            background-image: url('/images/ico_enough.svg');
            background-position: right 27px top 14px;
            background-repeat: no-repeat;
          }
          .explain {
            position: relative;
            padding-left: 16px;
            margin-left: 12px;
            color: #404040;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.4;
            letter-spacing: -0.9px;
            .bold {
              font-weight: 500;
            }
            &:before {
              content: '※';
              display: inline-block;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}
