@import '@pages/QuestionHeader.module.scss';

.foreign {
  .fileUpload {
    .bold {
      cursor: pointer;
      color: #006ac3;
      font-weight: 700;
    }
  }
}
