.Modal {
  min-width: 1150px;
  .title {
    color: #2e2e2e;
    font-size: 26px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -1px;
    & > span {
      color: #006ac3;
    }
  }
  .text {
    margin-top: 2px;
    color: #777c86;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.88px;
  }
  .chargeGuideWrapper {
    .leftTr {
      padding-left: 34px;
    }
    .rightTr {
      padding-right: 34px;
      text-align: right;
    }
    table {
      margin-top: 24px;
      border-collapse: collapse;
      width: 100%;
      color: #353333;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px; /* 144.444% */
      letter-spacing: -1px;
      th,
      td {
        text-align: left;
        padding: 12px 16px;
      }
      th {
        background-color: #f6f5f5;
        color: #353333;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 144.444% */
        letter-spacing: -1px;
      }
      td {
        color: #000;
        font-size: 28px;
        font-weight: 500;
        line-height: 100%; /* 28px */
      }
      .purpose {
        width: 250px;
        color: #000;
        font-size: 20px;
        padding: 24px 0 24px 34px;
        font-weight: 500;
        line-height: 100%; /* 20px */
        border-bottom: 1px solid #c6cbd5;
      }
      .exceedPurposeCounts {
        width: 250px;
        font-weight: 500;
        border-bottom: 1px solid #c6cbd5;
      }
      .purposeCharge {
        width: 250px;
        border-bottom: 1px solid #c6cbd5;
      }
      .totalPurposeCharge {
        width: 250px;
        padding-right: 34px;
        border-bottom: 1px solid #c6cbd5;
        text-align: right;
      }
      .blueText > td {
        color: #006ac3;
        & > span {
          color: #006ac3;
        }
      }
      .suffix {
        color: #000;
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 500;
        line-height: 24px; /* 150% */
        vertical-align: top;
        margin-left: 2px;
      }
    }
  }
}
