.container {
  display: flex;
  gap: 4px;

  height: fit-content;
  width: 100%;
  padding: 0 24px;

  .inputWrapper {
    height: 58px;
    flex: 1;

    display: flex;
    align-items: center;
    gap: 8px;

    border: 1px solid #c6cbd5;
    border-radius: 2px;

    padding: 0 14px 0 18px;
    background-color: white;

    input {
      flex: 1;
      height: 35px;

      color: #2e2e2e;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px; /* 194.444% */
      letter-spacing: -1.25px;

      ::placeholder {
        color: #777c86;
        opacity: 1; /* Firefox */
      }

      ::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: #777c86;
      }
    }

    .close {
      width: 32px;
      height: 32px;
    }
  }

  .searchButton {
    width: 130px;
    height: 58px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 2px;
    background: #006ac3;

    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
  }
}
