.modal {
  padding: 0 !important;

  button {
    top: 18px !important;
  }
}

.container {
  width: 1214px;
  height: 880px;
  max-width: 98vw;
  max-height: calc(90vh - 100px);

  position: relative;

  .header {
    min-height: 132px;
    padding: 24px 32px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px 8px 0px 0px;
    position: relative;

    .title {
      color: #1e1e22;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 24px */
    }

    .category {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: 29px;

      .itemsWrap {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;

        .item {
          background-color: white;

          color: #8c8c8c;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 27px */
          letter-spacing: -0.689px;

          word-break: keep-all;
          white-space: nowrap;
        }

        .active {
          color: #006ac3;
        }
      }

      .button {
        display: flex;
        align-items: center;
        gap: 7px;
        background-color: white;

        color: #006ac3;
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
        letter-spacing: -1px;

        word-break: keep-all;
        white-space: nowrap;

        .icon {
          width: 22px;
          height: 22px;

          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .content {
    background: #f5f4f4;

    display: flex;
    flex-direction: column;
    gap: 12px;

    height: calc(100% - 132px);
    overflow-y: scroll;

    padding: 24px 32px 120px;

    border-radius: 0px 0px 8px 8px;

    .consonantGroup {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .submit {
    display: flex;
    width: 424px;
    height: fit-content;
    padding: 13px 0px 15px 0px;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    background: #006ac3;

    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */

    position: absolute;

    top: auto !important;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
  }
}
