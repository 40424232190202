.numOfSharesToBeIssuedTable {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 108px;
  border-top: 1px solid #999fac;
  border-bottom: 1px solid #999fac;
  .numOfSharesBox {
    position: relative;
    width: 270px;
    .plusCircle {
      position: absolute;
      top: 21px;
      left: 114px;
    }
    .onRegisterBox,
    .newSharesBox {
      display: flex;
      justify-content: space-between;
      padding: 5px 10px;
      background-color: #f6f5f5;
      border-bottom: 1px solid #d6d6d6;
      color: #000;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      letter-spacing: -0.84px;
      .onRegister,
      .newShares {
        font-size: 16px;
        .text {
          margin-left: 3px;
          font-size: 15px;
        }
      }
    }
    .sumBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 10px;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.13;
      letter-spacing: -0.96px;
      .sum {
        font-size: 20px;
        .text {
          position: relative;
          top: -1px;
          margin-left: 4px;
          font-size: 16px;
        }
      }
    }
  }
  .arrowBox {
    position: relative;
    width: 40px;
    background-color: #eb4d59;
    .icon {
      position: absolute;
      top: 33px;
      left: 9px;
    }
  }
  .numOfSharesToBeIssuedBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 12px;
    width: 250px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.38;
    letter-spacing: -0.8px;
    .numOfSharesToBeIssued {
      font-size: 20px;
      .text {
        position: relative;
        top: -1px;
        margin-left: 4px;
        font-size: 16px;
      }
    }
  }
}

.tableExplain {
  margin-top: 11px;
  color: #eb4d59;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -0.5px;
}

.sharesText {
  display: inline-block;
  margin-left: 7px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.8px;
}
