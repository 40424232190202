.explainImgBox {
  padding: 8px;
  background-color: #ecf0f8;
}
.explain {
  margin-top: 10px;
  font-size: 16px;
  color: #000;
  letter-spacing: -0.48px;
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  .title {
    margin-bottom: 2px;
  }
  .content {
    margin-left: 18px;
    letter-spacing: -1px;
  }
}
