.questionHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 90;
  .content {
    height: 420px;
    position: relative;
    .leftBg {
      display: inline-block;
      height: 100%;
      width: calc((100% - 1240px) / 2);
      background: #0c73e3;
      position: absolute;
      left: 0;
      top: 0;
    }
    .rightBg {
      display: inline-block;
      height: 100%;
      width: calc(100% - (100% - 1240px) / 2);
      background-image: linear-gradient(to right, #0c73e3 0%, rgba($color: #352c2c, $alpha: 0) 93%),
        url('/images/headerBg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
      position: absolute;
      right: 0;
      top: 0;
    }
    .titleBox {
      padding-top: 58px;
      box-sizing: content-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 66px;
      .title {
        font-size: 54px;
        font-weight: 500;
        .text {
          position: relative;
          top: 6px;
          vertical-align: top;
          letter-spacing: -2.28px;
          margin-left: 3px;
          color: #fff;
        }
      }
    }
    .btnArea button {
      margin-left: 10px;
    }
    .stepBtnPre,
    .stepBtn {
      &:active {
        position: relative;
        top: 1px;
        left: 1px;
      }
      &:disabled {
        position: relative;
        top: 0;
        left: 0;
      }
    }
    .progressWrap {
      margin-top: 50px;
      min-width: 1240px;
    }
    .headerWrap {
      position: relative;
      .finish {
        position: absolute;
        right: -122px;
        bottom: -87px;
        &.hidden {
          opacity: 0;
          width: 0;
          height: 0;
        }
      }
    }
  }
  &.hidden {
    z-index: 9999;
    .content {
      height: 69px;
      .progressWrap {
        display: none;
      }
      .titleBox {
        padding-top: 11px;
        height: 48px;
        .title {
          font-size: 25px;
          img {
            width: 30px;
          }
          .text {
            top: 2px;
          }
        }
      }
    }
  }
}
