.registerInfo {
  .row {
    display: flex;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: -0.2px;
    &:first-child {
      margin-top: 0;
    }
    .registerTitle {
      flex-shrink: 0;
      width: 148px;
      color: #555;
    }
    .beforeInfo {
      margin-left: 18px;
      flex-shrink: 0;
      width: 400px;
    }
    .afterInfo {
      margin-left: 18px;
      flex-shrink: 0;
      width: 153px;
      font-weight: 500;
      &.delete {
        text-decoration: line-through;
      }
    }
    .changeType {
      margin-left: 22px;
      .blue {
        color: #006ac3;
      }
      .red {
        color: #eb4d59;
      }
      .green {
        color: #0fac88;
      }
      .gray {
        color: #808796;
      }
    }
  }
}

.executiveInfo {
  margin-top: 19px;
  &.keep {
    border-top: 1px solid #d8d8d8;
  }
  &.notKeep {
    margin-top: 0;
  }
  .executiveInfoTitle {
    margin-top: 19px;
    color: #2e2e2e;
    font-size: 22px;
    letter-spacing: -0.66px;
  }
  .executive,
  .newExecutive {
    display: flex;
    font-size: 20px;
    letter-spacing: -0.2px;
    &.keep {
      margin-top: 20px;
    }
    &.newExecutive {
      margin-top: 30px;
    }
    .title {
      flex-shrink: 0;
      width: 148px;
      color: #555;
    }
    .executiveDetail,
    .newExecutiveDetail {
      margin-left: 18px;
      .executiveDetailInfo,
      .newExecutiveDetailInfo {
        margin-top: 18px;
        &:first-child {
          margin-top: 0;
        }
        .infoBox {
          display: inline-flex;
          .namePosition {
            flex-shrink: 0;
            width: 193px;
            color: #000;
            .changeTypeBox {
              margin-top: 3px;
              font-size: 18px;
              letter-spacing: -0.18px;
              .changeType {
                margin-top: 3px;
                &.blue {
                  color: #006ac3;
                }
                &.red {
                  color: #eb4d59;
                }
                &.green {
                  color: #0fac88;
                }
                &.gray {
                  color: #808796;
                }
              }
            }
          }
          .detail {
            margin-left: 14px;
            color: #555;
            font-size: 18px;
            div {
              margin-top: 5px;
              &:first-child {
                margin-top: 0;
              }
            }
            .newAddressInfo {
              display: inline-flex;
              .newAddress {
                min-width: 125px;
              }
              .newAddressBox {
                margin-left: 3px;
                margin-top: 0;
              }
            }
            .farmerFishermanCheck {
              color: #006ac3;
            }
          }
        }
      }
      .noNewRepresentative {
        color: #777c86;
      }
    }
  }
}
