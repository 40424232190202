.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .group {
    display: flex;
    align-items: center;
    gap: 8px;

    .addButton {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 2px;

      padding: 6px 10px;

      border-radius: 25px;
      background: #00b4e3;

      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 14px */

      .icon {
        width: 16px;
        height: 16px;
      }

      &.added {
        background: #777c86;
      }
    }
  }

  .purposeWrap {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .dropDown {
    display: flex;
    flex-direction: column;
    gap: 8px;

    margin-left: 30px;
  }
}
