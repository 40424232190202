.title {
  color: #2e2e2e;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: -1.04px;
  line-height: 1.46;
}

.between {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .titleWrapper {
    color: #2e2e2e;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: -1.04px;
    line-height: 1.46;
    .bold {
      color: #006ac3;
    }
  }
}

.checkWrap {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 29px 30px;
  border: 1px solid #c6cbd5;
  border-radius: 4px;
  .option {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    color: #999fac;
    font-size: 20px;
    font-weight: 500;
  }
  &.active,
  &.announceActive {
    outline: 1px solid #006ac3;
    border: 1px solid #006ac3;
    background-color: rgba(0, 106, 195, 0.1);
    .option {
      color: #000;
    }
  }
  &.announceActive {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.announce {
  width: 100%;
  padding: 24px 30px 29px;
  outline: 1px solid #006ac3;
  border: 1px solid #006ac3;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  &.announceActive {
    border-top: none;
  }
  .content {
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
    .title {
      margin-bottom: 10px;
      color: #000;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.3;
      .bold {
        color: #006ac3;
      }
      .titleExplain {
        position: relative;
        display: inline-block;
        padding-left: 17px;
        color: #777c86;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.45;
        &:before {
          content: '※';
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    .radio {
      margin-top: 10px;
    }
  }
}
.announceExplain {
  margin-top: 8px;
  color: #777c86;
  font-size: 16px;
  letter-spacing: -0.64px;
}
