@import '@pages/ModalContent/ModalContent.module.scss';

.Modal {
  .title {
    width: 294px;
  }
  .modalContent {
    width: 437px;
  }
}
