@import '@pages/Establish/FinalSubmission/FinalSubmissionTable.module.scss';

.content {
  .finalPrice {
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
    padding: 51px 0 51px 30px;
    border-top: 4px solid #2e2e2e;
    border-bottom: 4px solid #2e2e2e;
    .final {
      position: relative;
      top: 7px;
      &.fail {
        top: 0;
      }
      color: #2e2e2e;
      font-size: 38px;
      font-weight: 500;
      letter-spacing: -0.95px;
      .explain {
        margin-left: 11px;
        color: #777c86;
        font-size: 20px;
        font-weight: 400;
      }
    }
    .price {
      color: #006ac3;
      font-size: 53px;
      font-weight: 500;
      letter-spacing: -1.33px;
      .won {
        position: relative;
        top: -6px;
        margin-left: 2px;
        font-size: 30px;
      }
      .priceFail {
        position: relative;
        top: 3px;
        font-size: 30px;
      }
    }
  }
  .finalSubmitBtn {
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
    .explain {
      color: #555;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.8;
      letter-spacing: -0.72px;
      .infoList {
        li {
          position: relative;
          margin-left: 30px;
          color: #777c86;
          font-size: 14px;
          line-height: 1.5;
          &:before {
            content: '-';
            display: inline-block;
            position: absolute;
            left: -10px;
            top: 0;
          }
          .balanceCertificateList {
            counter-reset: number 0;
            li {
              margin-left: 15px;
              counter-increment: number 1;
              &:before {
                position: absolute;
                top: 0;
                left: -15px;
                display: inline-block;
                content: counter(number) ')';
              }
            }
          }
        }
      }
    }
    .sumbitBtn {
      position: relative;
      top: 8px;
      z-index: 4500;
    }
  }
}
.questionBold {
  color: #eb4d59;
}
.businessPurpose {
  width: 100%;
  display: flex;
  .purposeBox {
    display: flex;
    gap: 40px;
    width: 100%;
    border-bottom: 1px solid #9fa4b0;
    padding-bottom: 16px;
    .purposeTitle {
      width: 140px;
      color: #000;
      margin-top: 5px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.48px;
      margin-bottom: 5px;
    }
    .purposeWrapper {
      width: 100%;
      margin-top: 5px;
      color: #000;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.48px;
      .purpose {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.48px;
        padding-right: 9px;
      }
      .recommend {
        color: #006ac3;
      }
      .direct {
        color: #404040;
      }
    }
  }
}

.alarmText {
  margin-top: 12px;
  margin-left: 144px;
  color: #a2a7b2;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.42px;
}
