.noChange {
  padding: 22px 18px;
  border: 1px solid #c6cbd5;
  border-radius: 4px;
}

.changeExecutiveMemberBox {
  position: relative;
  margin-top: 59px;
  width: 100%;
  .unCheck {
    position: absolute;
    top: -29px;
    right: 0;
    .unCheckBtn {
      font-weight: 500;
      &:active {
        position: relative;
        top: 1px;
        left: 1px;
      }
    }
  }
  .changeExecutiveMemberList {
    border-top: 2px solid #999fac;
    border-bottom: 2px solid #999fac;
    li {
      border-top: 1px solid #c6cede;
      &:first-child {
        border-top: none;
      }
      .changeExecutiveMember {
        padding: 23px 18px;
      }
    }
  }
}

.regExplain {
  display: inline-block;
  color: #585a5f;
  font-size: 16px;
  letter-spacing: -0.4px;
}
