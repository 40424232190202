.title {
  color: #000;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: -0.91px;
  .bold {
    color: #006ac3;
  }
}
.table {
  margin-top: 24px;
  width: 100%;
  thead {
    tr {
      th {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.64px;
        background-color: #999fac;
        &:nth-child(1) {
          padding: 11px 6px 12px 37px;
          text-align: left;
        }
        &:nth-child(2) {
          padding: 11px 30px 12px 6px;
        }
        &:nth-child(3) {
          padding: 11px 10px 12px 0;
        }
        &:nth-child(4) {
          padding: 11px 10px 12px 10px;
        }
        &:nth-child(5) {
          padding: 11px 10px 12px 10px;
        }
        &:nth-child(6) {
          padding: 11px 24px 12px 10px;
          text-align: right;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        color: #000;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -0.6px;
        border-bottom: 1px solid #c6cede;
        &:nth-child(1) {
          position: relative;
          padding: 14px 6px 14px 37px;
          .index {
            position: absolute;
            top: center;
            left: 8px;
          }
          .name {
            font-weight: 500;
          }
          .shareTag {
            display: inline-block;
            margin-top: 5px;
            font-size: 16px;
          }
        }
        &:nth-child(2) {
          padding: 14px 30px 14px 6px;
          text-align: center;
        }
        &.stockName {
          padding: 14px 10px 14px 0;
          text-align: left;
        }
        &.investment {
          padding: 14px 10px 14px 10px;
          text-align: right;
        }
        &.stock {
          padding: 14px 10px 14px 10px;
          text-align: right;
        }
        &.price {
          padding: 14px 24px 14px 10px;
          text-align: right;
        }
      }
    }
  }
  tfoot {
    tr {
      td {
        color: #006ac3;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.6px;
        &:nth-child(1) {
          padding: 13px 6px 0 37px;
        }
        &:nth-child(2) {
          padding: 13px 10px 0 6px;
          text-align: right;
        }
        &:nth-child(3) {
          padding: 13px 10px 0 10px;
          text-align: right;
        }
      }
    }
  }
}
