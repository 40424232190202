.explainImgBox,
.explainImgBoxLimit {
  position: relative;
  width: 100%;
  height: 293px;
  background-color: #d3dae2;
  border-radius: 4px;
  &.explainImgBoxLimit {
    height: 181px;
  }
  .sealProvidingImg {
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
