.Modal {
  width: 1180px;
  max-width: 90vw;
  max-height: 90vh;

  .title {
    color: #1e1e22;
    font-family: 'Noto Sans KR';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 24px */

    margin-bottom: 24px;
  }
  .modalContentWrapper {
    .text {
      margin-top: 2px;
      color: #777c86;
      font-size: 16px;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: -0.88px;
    }
    .chargeGuideWrapper {
      .leftTr {
        padding-left: 34px;
      }
      .rightTr {
        padding-right: 34px;
        text-align: right;
      }
      table {
        margin-top: 24px;
        border-collapse: collapse;
        width: 100%;
        color: #353333;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px; /* 144.444% */
        letter-spacing: -1px;
        th,
        td {
          text-align: left;
          padding: 12px 16px;
        }
        th {
          background-color: #f6f5f5;
          color: #353333;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 144.444% */
          letter-spacing: -1px;
        }
        td {
          color: #000;
          font-size: 28px;
          font-weight: 500;
          line-height: 100%; /* 28px */
        }
        @mixin purposeTd {
        }

        .purpose {
          width: 250px;
          color: #000;
          font-size: 20px;
          padding: 24px 0 24px 34px;
          font-weight: 500;
          line-height: 100%; /* 20px */
          border-bottom: 1px solid #c6cbd5;
        }
        .totalPurposeCounts {
          width: 250px;
          font-weight: 500;
          border-bottom: 1px solid #c6cbd5;
        }
        .exceedPurposeCounts {
          width: 250px;
          font-weight: 500;
          border-bottom: 1px solid #c6cbd5;
        }
        .purposeCharge {
          width: 250px;
          border-bottom: 1px solid #c6cbd5;
        }
        .totalPurposeCharge {
          width: 250px;
          padding-right: 34px;
          border-bottom: 1px solid #c6cbd5;
          text-align: right;
        }
        .blueText > td {
          color: #006ac3;
          & > span {
            color: #006ac3;
          }
        }
        .suffix {
          color: #000;
          font-size: 16px;
          margin-bottom: 10px;
          font-weight: 500;
          line-height: 24px; /* 150% */
          vertical-align: top;
          margin-left: 2px;
        }
      }
    }

    .next {
      display: flex;
      width: 424px;
      height: fit-content;
      padding: 13px 0px 15px 0px;
      justify-content: center;
      align-items: center;

      border-radius: 4px;
      background: #006ac3;

      margin: 48px auto 0;

      color: #fff;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 30px */
    }
  }
}
