.subContent {
  color: #555555;
  font-weight: 300;
  font-size: 18px;
  margin-left: 20px;
}
.table {
  width: 750px;
  color: #555;
  th {
    font-size: 18px;
    font-weight: 400;
    background-color: #f6f5f5;
    height: 55px;
    padding: 0 20px;
    line-height: 1.19;
  }
  td {
    font-size: 16px;
    font-weight: 400;
    height: 50px;
    border-bottom: 1px solid #d8d8d8;
    &:nth-child(1) {
      font-size: 500;
      color: #000;
      font-size: 20px;
    }
    padding: 0 20px;
  }
}
.download {
  margin-left: 10px;
  text-decoration: underline;
  &:nth-child(1) {
    margin-left: 0;
  }
}
.person {
  margin-left: 20px;
  &:nth-child(1) {
    margin-left: 0;
  }
}
