.table {
  width: 984px;
  th {
    height: 55px;
    background-color: #f6f5f5;
    color: #555;
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: -0.54px;
    &:nth-child(1) {
      padding: 0 15px 0 20px;
    }
    &:nth-child(2) {
      padding: 0 20px 0 15px;
    }
    &:nth-child(n + 3):nth-child(-n + 4) {
      padding: 0 20px;
    }
    &:nth-child(5) {
      padding: 0 20px;
      font-size: 16px;
      letter-spacing: -0.8px;
    }
  }
  td {
    padding: 10px 0;
    border-bottom: 1px solid #d8d8d8;
    color: #555;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: -0.16px;
    &:nth-child(1) {
      padding-left: 20px;
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }
    &:nth-child(2) {
      padding: 0 20px 0 15px;
    }
    &:nth-child(n + 3):nth-child(-n + 4) {
      padding: 0 20px;
    }
    &:nth-child(5) {
      padding: 0 20px;
    }
  }
}
