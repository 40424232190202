.title {
  color: #2e2e2e;
  font-size: 26px;
  font-weight: 500;
  line-height: 38px; /* 146.154% */
  letter-spacing: -1px;
  margin-bottom: 16px;
}
.purposeBoxWrapper {
  display: flex;
  flex: 1;
  gap: 60px;
  .explainSelectPurposeBox {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 560px;
    height: 545px;
    padding: 8px;
    border-radius: 4px;
    background: #ecf0f8;
    .titleContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 44px;
      border-radius: 4px;
      border: 1px solid #d3dae2;
      background: #e0ecf9;
      color: #2e2e2e;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      letter-spacing: -1px;
    }
    .selectPurposeInfoBox {
      background-color: white;
      border: 1px solid #d3dae2;
      border-radius: 4px;
      .titleWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #006ac3;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: -1px;
        .numberContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 22px;
          height: 20px;
          font-size: 12px;
          color: white;
          background-color: #006ac3;
          border-radius: 9999px;
        }
      }
      .bodyText {
        display: flex;
        align-items: center;
        color: #585a5f;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.48px;
        margin-top: 12px;
        .customUl {
          margin-right: 4px;
          width: 4px;
          height: 4px;
          border-radius: 9999px;
          background-color: #585a5f;
        }
      }
      .exampleBoxWrapper {
        display: flex;
        width: 100%;
        margin-top: 6px;
        .exampleBox {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          border-radius: 4px 0 0 4px;
          width: 58px;
          background: #d4d8dc;
          color: #6c6c6c;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.48px;
        }
        .exampleContentBox {
          display: flex;
          flex-direction: column;
          width: calc(100% - 58px);
          padding: 20px;
          background: #e9ecf0;
          .exampleContentWrapper {
            display: flex;
            align-items: center;
            gap: 15px;
            font-size: 14px;
            line-height: 150%; /* 21px */
            letter-spacing: -0.48px;
            .lineThroughText {
              text-decoration: line-through;
              color: #eb4d59;
            }
            .subTitle {
              color: #006ac3;
              font-weight: 500;
            }
            .arrowRightIcon {
              width: 23px;
              height: 23px;
            }
            .content {
              color: #2c2c2c;
              font-weight: 400;
            }
          }
          .plusIcon {
            margin: 12px 0 12px 20px;
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}
.qnaContainer {
  width: calc(100% - 620px);
}
.qnaBoxTitle {
  margin-bottom: 12px;
}
.qnaBoxSubTitle {
  margin-top: 6px;
  margin-bottom: 4px;
}
.qnaBoxExplain {
  color: #585a5f;
  font-size: 16px;
  font-weight: 300;
  line-height: 150%; /* 24px */
  letter-spacing: -0.48px;
}
.qnaBoxExplainGap {
  margin-top: 4px;
}
.squareBtn {
  display: block;
  margin-top: 8px;
  margin-bottom: 9px;
}
.purpose {
  li {
    position: relative;
    margin-top: 11px;
    margin-left: 10px;
    color: #404040;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.44;
    letter-spacing: -0.96px;
    &:first-child {
      margin-top: 0;
    }
    &:before {
      content: '-';
      display: inline-block;
      position: absolute;
      top: 0;
      left: -10px;
    }
    .purposeExplain {
      display: inline-block;
      color: #6c6c6c;
      font-size: 15px;
      font-weight: 400;
    }
  }
}
.defList {
  .textWrap {
    padding: 0 10px 18px;
    border-bottom: 1px solid #d3d3d3;
    .wrap {
      display: inline-block;
      text-align: center;
      .title {
        margin-bottom: 3px;
        color: #404040;
        font-size: 14px;
      }
      .content {
        color: #006ac3;
        font-size: 17px;
      }
    }
    .arrow {
      margin: 0 14px;
      position: relative;
      top: 5px;
    }
    .plusGray {
      margin: 0 15px 0 20px;
      position: relative;
      top: 1px;
    }
  }
  .exampleWrap {
    display: inline-flex;
    justify-content: space-between;
    margin-top: 18px !important;
    width: 100%;
    .area {
      display: inline-block;
      position: relative;
      top: 2px;
      color: #404040;
      font-size: 14px;
    }
    .areaExample {
      display: inline-block;
      width: 302px;
      color: #6c6c6c;
      font-size: 15px;
      .bold {
        font-size: 15px;
        color: #006ac3;
        font-weight: 500;
      }
    }
  }
}
li.helperClass {
  box-shadow: 5px 5px 8px 5px rgba($color: #000000, $alpha: 0.1);
  z-index: 999999;
}
