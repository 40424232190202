.branchOfficeRelocate {
  .relocateBox {
    display: block;
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
    .originAddressBox,
    .newAddressBox {
      display: flex;
      margin-top: 20px;
      &.originAddressBox {
        &:first-child {
          margin-top: 0;
        }
      }
      .dName {
        width: 148px;
        color: #555;
        font-weight: 400;
      }
      .data {
        .newBranch {
          .newBranchName,
          .newBranchCauseDate {
            margin-top: 8px;
          }
        }
      }
    }
  }
}
