.textBox {
  ol {
    padding-left: 15px;
    li::before {
      content: none;
    }
    li {
      list-style-type: decimal !important;
      padding-left: 3px;
      color: #4b4b4b;
    }
  }
}
