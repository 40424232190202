.liquidationInfo,
.executiveInfo {
  display: flex;
  margin-top: 13px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  &:first-child {
    margin-top: 0;
  }
  .liquidationNamePosition,
  .executiveNamePosition {
    width: 193px;
  }
  .liquidationDetail,
  .executiveDetail {
    margin-left: 14px;
    color: #555;
    font-size: 18px;
    letter-spacing: -0.18px;
    div {
      margin-top: 5px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
