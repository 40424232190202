.checkHeader {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  .checkAll {
    top: 1px;
  }
  .unCheckAll {
    font-size: 16px;
    font-weight: 500;
  }
}

.branchInfoList {
  margin-top: 16px;
  border-top: 2px solid #999fac;
  border-bottom: 2px solid #999fac;
  li {
    padding: 23px 18px;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.45;
    letter-spacing: -0.54px;
    border-bottom: 1px solid #c6cede;
    .check {
      top: 2px;
    }
    &.checkLi {
      background-color: #e5f0f9;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

.infoTable {
  margin-bottom: 25px;
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;
  tr {
    th {
      background-color: #e5f0f9;
      text-align: center;
    }
    td {
      text-align: left;
    }
    td,
    th {
      border-bottom: 1px solid #c6cbd5;
      border-left: 1px solid #c6cbd5;
      border-right: 1px solid #c6cbd5;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.5px;
      color: #000;
      padding: 20px;
      line-height: 29px;
    }
    &:first-child {
      td,
      th {
        border-top: 1px solid #c6cbd5;
      }
    }
  }
}
