@import '@pages/Establish/FinalSubmission/FinalSubmissionTable.module.scss';

.banner {
  position: relative;
  .content {
    display: inline-block;
    color: #000;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: -0.5px;
    .bold {
      color: #fd6200;
      font-weight: 500;
    }
  }
  .building {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: -21px;
  }
}
