.start,
.focus {
  position: relative;
  top: -50px;
  left: -45px;
  width: calc(100% + 90px);
  box-shadow: 0px 0px 0px 10000px rgba(0, 0, 0, 0.75);
  z-index: 5500;

  &.focus {
    padding: 40px 45px;
    border-radius: 8px;
    background-color: #fff;
    .row {
      &:nth-child(3) {
        margin-top: 0;
      }
    }
  }
}

.question {
  position: absolute;
  left: 0;
  bottom: -30px;
  transform: translateY(100%);
  display: flex;
  justify-content: space-between;
  width: 100%;

  .left {
    .helpme {
      position: relative;
      left: -9px;
      top: -4px;
      display: inline-block;
      vertical-align: top;
    }
    .qText {
      display: inline-block;
      margin-left: -5px;
      padding: 21px 40px;
      background-color: #fff;
      border-radius: 35px;
      border-top-left-radius: 8px;
      color: #000;
      font-size: 21px;
      font-weight: 500;
      line-height: 1.38;
      letter-spacing: -0.53px;

      .questionBold {
        color: #eb4d59;
      }
    }
  }

  .right {
    .btnYes,
    .btnNo {
      height: 70px;
      font-size: 20px;
      font-weight: 500;
      border-radius: 100px;
      box-shadow: 0 3px 6px 0 rgba(81, 96, 160, 0.15);
      &.btnYes {
        padding: 16px 38px;
        color: #fff;
        background-color: #006ac3;
        border: 2px solid #fff;
      }
      &.btnNo {
        margin-left: 12px;
        padding: 20px 38px;
        color: #006ac3;
        background-color: #fff;
        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}

.btnBack {
  cursor: pointer;
  position: absolute;
  top: -95px;
  left: 580px;
  width: 81px;
  height: 81px;
  background-color: transparent;
  background-image: url('/images/btn_back.svg');
  background-size: cover;
  background-repeat: no-repeat;
  vertical-align: top;
}
