.container {
  width: 100%;
  margin: 16px 0;
  min-height: 484px;
  display: flex;
  .leftBox {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
    width: 80px;
    min-height: 100%;
    background: #d4d8dc;
    color: #6c6c6c;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.48px;
  }
  .rightBox {
    width: calc(100% - 80px);
    height: 100%;
    background: #e9ecf0;
    border-radius: 0 4px 4px 0;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    .searchWrapper {
      display: flex;
      gap: 4px;
      .inputText {
        width: 314px;
        background: #fff;
        border-radius: 2px;
        border: 1px solid #c6cbd5;
        color: #2e2e2e;
        font-size: 14px;
        font-weight: 400;
        line-height: 35px; /* 250% */
        letter-spacing: -1.25px;
        padding: 5px 14px;
      }
      .searchButton {
        width: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 21px */
        background-color: #006ac3;
        border-radius: 2px;
      }
    }
    .selectedPurposeWrapper {
      width: 100%;
      height: 100%;
      background-color: white;
      padding: 4px 12px 12px 12px;
      margin-top: 12px;
      .titleContainer {
        display: flex;
        align-items: center;
        height: 35px;
        gap: 8px;
        .titleWrapper {
          display: flex;
          align-items: center;
          color: #2e2e2e;
          font-size: 14px;
          font-weight: 500;
          line-height: 35px; /* 250% */
          letter-spacing: -1.25px;
          border-bottom: 1px solid #c6cbd5;
          .folderIcon {
            margin-right: 4px;
            width: 16px;
            height: 16px;
          }
          .arrowIcon {
            margin: 0 4px;
          }
          .countPurpose {
            width: 135px;
            color: #777c86;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 35px; /* 250% */
            letter-spacing: -1.25px;
            margin-left: 4px;
          }
          .arrowDown {
            width: 14px;
            height: 14px;
          }
        }
      }
      .purposeListWrapper {
        display: flex;
        align-items: center;
        gap: 4px;
        margin: 6px 0 0 24px;
        padding-bottom: 6px;
        color: #404040;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        letter-spacing: -1.25px;
        border-bottom: 1px solid #c6cbd5;
        .noteIcon {
          width: 16px;
          height: 16px;
          margin-left: 4px;
        }
      }
    }
    .selectedCheckbox {
      width: 16px;
      height: 16px;
    }
  }
}
