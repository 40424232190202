@import '@commonComponents/FocusBox/FocusBox.module.scss';

.table {
  position: relative;
  margin-top: 48px;
  font-size: 20px;
  letter-spacing: -0.5px;
  &:first-child {
    margin-top: 0;
  }
  .secretBtnArea {
    position: absolute;
    top: -3px;
    left: 165px;
    .secretBtn {
      padding: 0 5px;
      border: 2px solid #006ac3;
      color: #006ac3;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.9px;
      background-color: #fff;
      &:active {
        position: relative;
        left: 1px;
        top: 1px;
      }
    }
  }
  .title {
    padding-bottom: 9px;
    color: #2e2e2e;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -0.55px;
    border-bottom: 4px solid #2e2e2e;
  }
  .info {
    .row {
      display: flex;
      margin-top: 24px;
      .dName {
        width: 166px;
        flex-shrink: 0;
        color: #555;
        font-weight: 300;
      }
      .data {
        color: #000;
        font-weight: 500;
        .shareholder,
        .executive {
          display: flex;
          padding: 13px 0;
          width: 840px;
          border-top: 1px solid #c6cbd5;
          line-height: 1.6;
          &:first-child {
            padding-top: 0;
            margin-top: 0;
            border: none;
          }
          .shareholderPosition {
            position: relative;
            top: -6px;
            flex-shrink: 0;
          }
          .executivePosition {
            min-width: 71px;
            line-height: 1.1;
          }
          .shareholderInfo,
          .executiveInfo {
            position: relative;
            top: -6px;
            margin-left: 30px;
            .person {
              span {
                &:before {
                  content: '';
                  display: inline-block;
                  position: relative;
                  top: 3px;
                  margin: 0 14px;
                  width: 2px;
                  height: 20px;
                  border-radius: 50px;
                  background-color: #999fac;
                }
                &:first-child:before {
                  content: none;
                }
                &:nth-child(n + 3):nth-child(-n + 4) {
                  font-weight: lighter;
                }
              }
              .personType {
                position: relative;
                top: -2px;
                display: inline-block;
                padding: 0 5px;
                margin-left: 13px;
                background-color: #006ac3;
                color: #fff;
                font-size: 16px;
                font-weight: 500;
              }
              .resignation {
                position: relative;
                top: -2px;
                color: #006ac3;
                font-size: 16px;
              }
            }
            .address {
              margin-top: 4px;
              font-weight: lighter;
            }
            .positionInfo {
              color: #999fac;
              font-size: 18px;
              font-weight: 300;
              .star {
                position: relative;
                top: 4px;
              }
            }
            .representativeInfo {
              display: inline-flex;
              font-weight: lighter;
              .representativeTitle {
                margin-right: 14px;
              }
            }
          }
        }
        &.businessPurpose {
          margin-left: -20px;
          margin-top: -10px;
          .purpose {
            display: inline-block;
            margin-left: 20px;
            line-height: 1.75;
          }
        }
        .stampData {
          margin-top: 8px;
        }
        .stampSymbol {
          display: inline-block;
          margin-left: 5px;
          padding: 0 5px;
          border: 1px solid #bcbcbc;
          font-size: 16px;
          line-height: 1.75;
        }
      }
      &.before {
        .data {
          width: 887px;
          position: relative;
          .reviseBtn {
            position: absolute;
            right: -97px;
            transform: translateY(-25%);
            padding: 4px 16px;
          }
          .shareholderReviseBtn,
          .executiveMembersReviseBtn,
          .businessPurposeReviseBtn,
          .corpSealsReviseBtn {
            position: absolute;
            right: -97px;
            transform: translateY(-25%);
            top: 0;
            padding: 4px 16px;
            &.businessPurposeReviseBtn {
              transform: translateY(0);
            }
          }
          &.businessPurpose {
            width: 907px;
          }
        }
      }
    }
  }
}
