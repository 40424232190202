.representativeRow {
  display: inline-flex;
  flex-wrap: wrap;
  line-height: 1.3;
  .representative {
    width: 100%;
    &:nth-child(2) {
      margin-top: 35px;
    }
    .noChangeRepresentative,
    .changeRepresentative {
      display: inline-flex;
      width: 570px;
      &:nth-child(n + 3) {
        margin-top: 20px;
      }
      &:nth-child(even) {
        margin-left: 10px;
      }
      .dName {
        margin-right: 18px;
        .name {
          color: #000;
          font-weight: 500;
          line-height: 1.3;
        }
      }
      .data {
        width: 404px;
        font-weight: 400;
        .address {
          margin-top: 3px;
        }
        .newAddressInfo {
          margin-top: 12px;
          padding: 14px 20px;
          width: 360px;
          background-color: #e9ecf0;
          border-radius: 4px;
          .addressBox {
            padding: 12px 0;
            border-top: 1px solid #777c86;
            &:first-child {
              padding: 0;
              border-top: none;
            }
            &:last-child {
              padding-bottom: 0;
            }
            &:nth-child(2) {
              margin-top: 12px;
            }
            .newAddress {
              color: #555;
              letter-spacing: -0.2px;
            }
            .causeDate {
              margin-top: 8px;
              color: #555;
              font-size: 16px;
            }
          }
        }
        .alert {
          position: relative;
          padding-left: 18px;
          width: 394px;
          color: #777c86;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.8px;
          &:before {
            content: '※';
            display: inline-block;
            position: absolute;
            left: 0;
          }
        }
      }
    }
  }
}
