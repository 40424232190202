.branchOfficeInstall {
  line-height: 1.3;
  .installInfoBox {
    display: block;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #cccfd5;
    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: none;
    }
    .installBranchInfo {
      display: flex;
      .dName {
        width: 148px;
        color: #555;
        font-weight: 400;
      }
    }
    .installBranchManagerInfo {
      display: flex;
      margin-top: 5px;
      .dName {
        width: 148px;
        color: #555;
        font-weight: 400;
      }
      .data {
        .changeInfoDetail {
          display: flex;
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
