.resignationBox {
  margin-top: 24px;
  padding: 8px;
  width: 560px;
  border-radius: 4px;
  background-color: #ecf0f8;
  .resignationImg{
      width: 544px;
    }
  .resignationExplainBox {
    margin-top: 6px;
    padding: 18px 24px;
    width: 544px;
    height: 116px;
    border: 1px solid #d3dae2;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    .title {
      color: #000;
      font-size: 17px;
      font-weight: 500;
      letter-spacing: -0.72px;
      line-height: 1.41;
      width: 83px;
      flex-shrink: 0;
    }
    .content {
      margin-left: 12px;
      color: #000;
      font-size: 17px;
      font-weight: 400;
      line-height: 1.41;
      letter-spacing: -0.68px;
      .bold {
        color: #006ac3;
      }
      .explain {
        margin-top: 2px;
        font-size: 16px;
        letter-spacing: -0.64px;
        line-height: 1.5;
      }
    }

    // .resignation {
    //     width: 100%;
    //     display: flex;
    //     justify-content: space-between;
    //     color: #000;
    //     font-size: 16px;
    //     font-weight: 400;
    //     line-height: 1.38;
    //     letter-spacing: -0.32px;
    //     .bold {
    //         color: #006ac3;
    //     }
    // }
    // .explain {
    //     margin-top: 15px;
    //     padding-left: 17px;
    //     position: relative;
    //     color: #000;
    //     font-size: 15px;
    //     font-weight: 300;
    //     line-height: 1.53;
    //     letter-spacing: -0.3px;
    //     &:before {
    //         content: '※';
    //         display: inline-block;
    //         position: absolute;
    //         top: 0;
    //         left: -2px;
    //     }
    //     .bold {
    //         font-weight: 400;
    //     }
    // }
  }
}
.explainBox {
  margin-top: 6px;
  .title {
    position: relative;
    padding-left: 12px;
    color: #2e2e2e;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.41;
    letter-spacing: -0.34px;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 12px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #2e2e2e;
    }
  }
  .list {
    li {
      position: relative;
      padding-left: 8px;
      margin-top: 4px;
      color: #585a5f;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: -0.74px;
      &:before {
        content: '-';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
