.basicAnnounceBox {
  background-color: #e9ecf0;
  border-radius: 4px;
  margin-top: 24px;
  padding: 8px;
  width: 100%;
  .basicAnnounce {
    padding: 20px;
    width: 100%;
    background-color: #fff;
    .title {
      color: #000;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.96px;
      text-align: center;
    }
    .announce {
      margin-top: 14px;
      color: #404040;
      font-size: 17px;
      font-weight: 400;
      line-height: 1.47;
      letter-spacing: -0.85px;
    }
  }
}
