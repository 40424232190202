@import '@pages/ModalContent/ModalContent.module.scss';

.Modal {
  .title {
    width: 455px;
  }
  .modalContent {
    width: 500px;
  }
}
