.parValueOnRegisterBox {
  width: 100%;
  border-top: 1px solid #999fac;
  border-bottom: 1px solid #999fac;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  .title {
    padding: 12px 18px;
    background-color: #dce0e9;
  }
  .parValueOnRegister {
    padding: 17px 18px;
    .parValueOnRegisterText {
      display: inline-block;
      margin-left: 3px;
    }
  }
}

.newParValueText {
  display: inline-block;
  margin-left: 7px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.8px;
}
