.questionHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 90;
  .content {
    height: 420px;
    position: relative;
    .leftBg {
      display: inline-block;
      height: 100%;
      width: calc((100% - 1240px) / 2);
      background: #0c73e3;
      position: absolute;
      left: 0;
      top: 0;
    }
    .rightBg {
      display: inline-block;
      height: 100%;
      width: calc(100% - (100% - 1240px) / 2);
      background-image: linear-gradient(to right, #0c73e3 0%, rgba($color: #352c2c, $alpha: 0) 93%),
        url('/images/headerBg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
      position: absolute;
      right: 0;
      top: 0;
    }
    .titleBox {
      padding-top: 47px;
      text-align: center;
      :global .title {
        position: relative;
        font-size: 54px;
        font-weight: 500;
        color: #fff;
        vertical-align: top;
        letter-spacing: -2.28px;
        line-height: 1.36;
        :global .bold {
          color: #ff9d00;
        }
      }
      :global .explain {
        position: relative;
        margin-top: 10px;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: -0.5px;
        color: #fff;
      }
    }
  }
}
