.explainImgBox {
  width: 100%;
  padding: 8px;
  background-color: #ecf0f8;
  border-radius: 4px;
}
.fileUpload {
  .bold {
    cursor: pointer;
    color: #006ac3;
    font-weight: 700;
  }
}
