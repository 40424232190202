.rowContent {
  margin-top: 40px;
  &:first-child {
    margin-top: 0;
  }
  .title {
    color: #000;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.72px;
    .bold {
      color: #006ac3;
    }
  }
  .sub {
    margin-top: 3px;
    color: #777c86;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.8px;
  }
  .extra {
    margin-top: 20px;
    padding: 13px 18px;
    width: 316px;
    height: 58px;
    border: 1px solid #c6cbd5;
    background-color: #f1f2f5;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
  }
  .freeIssueTable {
    margin-top: 20px;
    width: 100%;
    thead {
      tr {
        th {
          padding: 11px 0;
          background-color: #999fac;
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.54px;
          &:nth-child(1) {
            padding-left: 37px;
            padding-right: 15px;
            text-align: left;
          }
          &:nth-child(2) {
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;
          }
          &:nth-child(3) {
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;
          }
          &:nth-child(4) {
            padding-left: 15px;
            padding-right: 24px;
            text-align: right;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 14px 0;
          border-bottom: 1px solid #c6cede;
          color: #000;
          font-size: 20px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.6px;
          &:nth-child(1) {
            padding-left: 8px;
            padding-right: 15px;
            font-weight: 500;
            .index {
              display: inline-block;
              margin-right: 6px;
              width: 24px;
            }
          }
          &:nth-child(2) {
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;
          }
          &.issue {
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;
          }
          &.stock {
            padding-left: 15px;
            padding-right: 24px;
            text-align: right;
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-top: 14px;
          color: #006ac3;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: -0.6px;
          line-height: normal;
          &:nth-child(1) {
            padding-left: 37px;
            padding-right: 15px;
            text-align: left;
          }
          &:nth-child(2) {
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;
          }
          &:last-child {
            padding-left: 15px;
            padding-right: 24px;
            text-align: right;
          }
        }
      }
    }
  }
}
.capitalChange {
  margin-top: 20px;
  width: 560px;
  li {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #c6cede;
    padding: 14px 8px;
    &:last-child {
      border-bottom: 2px solid #999fac;
      color: #006ac3;
    }
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.54px;
  }
}
