.QusetionContent {
  background-color: #fff;
  border-radius: 4px;
  width: 1240px;
  margin: 0 auto;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px 0 rgba(81, 96, 160, 0.15);
  padding: 60px 45px;
  &:nth-child(1) {
    padding-top: 45px;
  }
  &:nth-child(2) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:last-child {
    margin-bottom: 500px;
  }
  &.ListQusetionContent {
    //주주 & 임원
    padding: 0;
    margin-top: 8px;
    border-radius: 4px;
    &:nth-child(2) {
      margin-top: 0;
      padding: 21px 45px 22px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &:last-child {
      background-color: #eff0f2;
      box-shadow: none;
    }
  }
  .spaceBetween {
    display: flex;
    justify-content: space-between;
    & + .spaceBetween {
      margin-top: 100px;
    }
    .infoSection {
      width: 560px;
      .content {
        &:first-child {
          margin-top: 0;
        }
        margin-top: 100px;
      }
      .titleWrap {
        margin-bottom: 24px;
        width: 100%;
        &.marginTop {
          margin-top: 100px;
        }
        .title {
          color: #2e2e2e;
          font-size: 26px;
          font-weight: 500;
          letter-spacing: -1.04px;
          line-height: 1.46;
          &.inside {
            font-size: 24px;
          }
          .bold {
            color: #006ac3;
          }
        }
        .titleExplain {
          position: relative;
          color: #777c86;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: -0.88px;
          line-height: 1.4;
          width: 100%;
          .bold {
            font-weight: 500;
          }
          &:not(.noneMark) {
            padding-left: 17px;
          }
          &:not(.noneMark):before {
            content: '※';
            display: inline-block;
            position: absolute;
            left: 0;
            top: 1px;
          }
          &.noneMark {
            padding-left: 10px;
            &:before {
              content: '*';
              display: inline-block;
              position: absolute;
              top: 3px;
              left: 0;
            }
          }
          &.noneBefore {
            padding-left: 0;
            &:before {
              content: none;
            }
          }
          & + .titleExplain {
            margin-top: 2px;
          }
        }
        .titleExTextBox {
          margin-top: 3px;
          width: 100%;
          .nameTextBox {
            margin-right: 8px;
            color: #4b4b4b;
            font-size: 15px;
            font-weight: 400;
            &:last-child {
              margin-right: 0;
            }
            .text {
              vertical-align: middle;
            }
            .arrow {
              position: relative;
              top: 1px;
              margin: 0 3px;
              vertical-align: middle;
            }
          }
          .foreignAddressTextBox {
            color: #4b4b4b;
            font-size: 15px;
            font-weight: 400;
            &:last-child {
              margin-top: 4px;
            }
            .text {
              vertical-align: middle;
            }
          }
        }
      }
      .answerList {
        width: 100%;
        background-color: #fff;
        border: 1px solid #c6cbd5;
        border-radius: 4px;
        li {
          border-bottom: 1px solid #c6cbd5;
          .answerLabel {
            padding: 19px 18px;
          }
          &:first-child {
            border-top: none;
          }
          .certificateName {
            .certificateNameList {
              border-top: 1px solid #c6cbd5;
              li {
                margin-left: 70px;
                padding: 22px 70px 22px 0;
                border: none;
                border-top: 1px solid #c6cbd5;
                color: #000;
                font-size: 20px;
                font-weight: 400;
                letter-spacing: -0.8px;
                &:first-child {
                  border: none;
                }
              }
            }
            &.active {
              background-color: rgba(0, 106, 195, 0.1);
              .certificateNameList {
                li {
                  color: #006ac3;
                }
              }
            }
          }
        }
        > li:last-child {
          border-bottom: none;
        }
        & + .rowWrap {
          margin-top: 40px;
        }
      }
      .rowWrap {
        margin-top: 10px;
        width: 100%;
        .select,
        .inputText {
          margin-right: 4px;
          &:last-child {
            margin-right: 0;
          }
        }
        & + .titleWrap {
          margin-top: 120px;
        }
        .symbol {
          margin-left: -2px;
          display: inline-block;
          .symbolIcon {
            position: relative;
            top: 5px;
            color: #404040;
            font-size: 40px;
            letter-spacing: -4.5px;
            font-weight: 300;
          }
          .symbolText {
            position: relative;
            display: inline-flex;
            height: 58px;
            align-items: center;
            color: #000;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: -0.88px;
            max-width: 57px;
            &.fontSize {
              top: -3px;
              margin-right: 3px;
              font-size: 15px;
              vertical-align: super;
            }
          }
        }
      }
      .nameInput {
        margin-left: 8px;
        color: #000;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: -0.55px;
      }
      .priceTable {
        width: 100%;
        border-top: 2px solid #999fac;
        border-bottom: 2px solid #999fac;
        th {
          padding: 18px 0 17px;
          border-bottom: 1px solid #c6cede;
          color: rgb(53, 51, 51);
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          &:last-child {
            padding-right: 26px;
            text-align: right;
          }
        }
        .priceContentRow {
          td {
            padding: 33px 0;
            font-size: 26px;
            font-weight: 500;
            text-align: center;
            .priceContentWrap {
              position: relative;
              .text {
                position: relative;
                top: -5px;
                margin-left: 1px;
                font-size: 14px;
                font-weight: 500;
              }
              .resultText {
                position: relative;
                top: -5px;
                margin-left: 1px;
                color: #006ac3;
                font-size: 14px;
                font-weight: 500;
              }
              .mul,
              .plusBlack {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -26px;
              }
              .result {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -26px;
              }
            }
          }
          .resultPrice {
            padding-right: 10px;
            text-align: right;
            color: #006ac3;
            font-size: 32px;
          }
        }
      }
      .birthWrap {
        display: inline-block;
        margin-left: 24px;
        &:first-child {
          margin-left: 0;
        }
        .birthText {
          margin-left: 8px;
          color: #000;
          font-size: 22px;
          font-weight: 500;
        }
      }
      .newAddressWrap {
        margin-top: 40px;
        .title {
          color: #000;
          font-size: 26px;
          font-weight: 500;
          letter-spacing: -0.78px;
        }
        .newAddressTable {
          margin-top: 24px;
          width: 100%;
          border: 1px solid #c6cbd5;
          border-radius: 4px;
          .address,
          .date {
            display: flex;
            border-top: 1px solid #c6cbd5;
            &:first-child {
              border-top: none;
            }
            .addressTitle,
            .dateTitle {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 116px;
              background-color: #e5f0f9;
              color: #4b4b4b;
              font-size: 15px;
              font-weight: 400;
              line-height: 1.33;
              letter-spacing: -0.3px;
              text-align: center;
            }
            .originAddress,
            .originCauseDate {
              padding: 10px 16px;
              width: 444px;
              color: #000;
              font-size: 16px;
              font-weight: 400;
              line-height: 1.25;
              .orignAddressExplain {
                padding-left: 17px;
                position: relative;
                color: #006ac3;
                font-size: 15px;
                font-weight: 300;
                line-height: 1.7;
                letter-spacing: -0.3px;
                &:before {
                  content: '※';
                  display: inline-block;
                  position: absolute;
                  top: 0;
                  left: -2px;
                }
              }
            }
          }
        }
        .newAddressExplain {
          padding-left: 18px;
          margin-top: 8px;
          position: relative;
          color: #404040;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.38;
          letter-spacing: -0.32px;
          &:before {
            content: '※';
            display: inline-block;
            position: absolute;
            top: 0;
            left: -2px;
          }
          .bold {
            color: #006ac3;
          }
        }
      }
      .addressBadgeTitle,
      .reportDateBadgeTitle {
        margin-bottom: 8px;
        color: #000;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -0.6px;
      }
      .addressBadge {
        margin-bottom: 24px;
        position: relative;
        width: 100%;
        height: 424px;
        background-color: #d3dae2;
        .addressBadgeImg {
          display: inline-block;
          position: absolute;
          top: 9px;
          left: 8px;
        }
      }
      .reportDateBadge {
        margin-bottom: 24px;
        position: relative;
        width: 100%;
        height: 265px;
        background-color: #d3dae2;
        .reportDateBadgeImg {
          display: inline-block;
          position: absolute;
          top: 9px;
          left: 9px;
        }
      }
      .causeDateBadge {
        margin-bottom: 18px;
        position: relative;
        width: 100%;
        height: 240px;
        background-color: #d3dae2;
        .causeDateBadgeImg {
          display: inline-block;
          position: absolute;
          top: 8px;
          left: 8px;
        }
        &.date {
          height: 439px;
          img {
            width: 544px;
          }
        }
      }
      .registrationBadge {
        margin-bottom: 18px;
        position: relative;
        width: 100%;
        height: 439px;
        background-color: #d3dae2;
        .registartionBadgeBox {
          position: absolute;
          top: 8px;
          left: 8px;
          width: 544px;
          height: 423px;
          background-color: #fff;
          .registrationBadgeImg {
            display: inline-block;
            position: absolute;
            top: 18px;
            left: 27px;
          }
          .registrationBadgeTextBox {
            position: absolute;
            top: 262px;
            left: 27px;
            width: 492px;
            .registrationBadgeText {
              position: relative;
              padding-left: 19px;
              color: #404040;
              font-size: 15px;
              line-height: 1.4;
              letter-spacing: -0.75px;
              .text {
                margin-top: 3px;
                &:first-child {
                  margin-top: 0;
                }
                .boldBlue {
                  color: #006ac3;
                  font-weight: 700;
                }
                .bold {
                  font-weight: 700;
                }
              }
              &:before {
                display: inline-block;
                position: absolute;
                left: -1px;
                top: 3px;
                content: '';
                width: 16px;
                height: 16px;
                background-image: url('/images/registrationIcon.svg');
                background-repeat: no-repeat;
                background-size: cover;
              }
            }
            .registrationBadgeTextExplain {
              position: relative;
              margin-top: 15px;
              padding-left: 19px;
              color: #404040;
              font-size: 14px;
              line-height: 1.4;
              letter-spacing: -0.75px;
              .bold {
                font-weight: 700;
              }
              &:before {
                display: inline-block;
                content: '※';
                position: absolute;
                left: 0;
              }
            }
          }
        }
      }
      .addressCheck {
        width: 100%;
        border-top: 1px solid #999fac;
        border-bottom: 1px solid #999fac;
        .address {
          padding: 12px 18px;
          background-color: #dce0e9;
          color: #000;
          font-size: 18px;
          font-weight: 500;
          line-height: 1.11;
        }
        .originAddress,
        .originCauseDate {
          padding: 17px 18px;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.3;
          letter-spacing: -0.4px;
          &.originCauseDate {
            border-top: 1px solid #c6cede;
          }
        }
        & + .answerList {
          margin-top: 30px;
        }
      }
      .addressCheckExpian {
        position: relative;
        margin-top: 3px;
        margin-bottom: 24px;
        padding-left: 18px;
        color: #404040;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: -0.32px;
        .bold {
          font-weight: 500;
        }
        &:before {
          display: inline-block;
          content: '※';
          position: absolute;
          left: -2px;
          top: 0;
        }
      }
      .newAddressList {
        margin-top: 32px;
        position: relative;
        .newAddressTitle {
          position: relative;
          padding: 17px 51px;
          color: #000;
          font-size: 24px;
          font-weight: 500;
          letter-spacing: -0.72px;
          background-color: #f3f4f7;
          border-top: 2px solid #999fac;
          &:before {
            display: inline-block;
            content: '';
            position: absolute;
            left: 24px;
            top: 23px;
            width: 14px;
            height: 14px;
            border: 4px solid #000;
            border-radius: 100%;
          }
        }
        .newAddressBox {
          margin-top: 18px;
        }
        .closeBtn {
          position: absolute;
          top: 15px;
          right: 20px;
        }
      }
      .newAddressAddBtn {
        margin-top: 24px;
        width: 100%;
        height: 58px;
        background-color: transparent;
        border: 1px dashed #999fac;
        border-radius: 4px;
        color: #999fac;
        font-size: 16px;
        font-weight: 500;
        .bold {
          color: #007ac3;
        }
        .addGray {
          position: relative;
          top: -2px;
          width: 19px;
          height: 19px;
          margin-left: 5px;
          vertical-align: middle;
        }
      }
      .causeDateBox {
        position: relative;
        margin-top: 46px;
        .causeDateTitle {
          position: absolute;
          top: -24px;
          left: 0;
          color: #777c86;
          font-size: 17px;
          font-weight: 400;
          &:after {
            position: absolute;
            top: 0;
            left: 102px;
            display: inline-block;
            content: '*';
            color: #fc526e;
            font-size: 17px;
            font-weight: 400;
            letter-spacing: -0.8px;
          }
        }
      }
    }
    .qnaSection {
      width: 520px;
      .qnaTitle {
        margin-bottom: 24px;
        color: #2e2e2e;
        font-size: 23px;
        font-weight: 500;
        margin-top: 6px;
        &.inside {
          font-size: 21px;
        }
        .tip {
          margin-left: 9px;
        }
        & + .qnaBox {
          margin-top: 0;
        }
      }
      .noTitle {
        margin-top: 4px;
        .qnaBox {
          &:first-child {
            margin-top: 0;
          }
        }
      }
      .qnaBox {
        margin-top: 18px;
        &:first-child {
          margin-top: 0;
        }
      }
      :global {
        .hide {
          :local .qnaBox {
            margin-top: 16px;
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
      .answer {
        margin-top: 9px;
        & + .answer {
          margin-top: 7px;
        }
        & + .defList {
          margin-top: 6px;
        }
      }
      .explain {
        margin-top: 4px;
        color: #585a5f;
        font-size: 16px;
        letter-spacing: -0.8px;
        line-height: 1.5;
        font-weight: 300;
        .warn {
          color: #eb4d59;
          font-weight: 400;
        }
        & + .answer {
          margin-top: 9px;
        }
        & + .explain {
          margin-top: 8px;
        }
        & + .defList {
          margin-top: 10px;
        }
      }
      .defList {
        color: #2e2e2e;
        letter-spacing: -0.88px;
        line-height: 1.4;
        .term {
          margin-top: 6px;
          font-size: 17px;
          font-weight: 400;
          &:before {
            content: '';
            display: inline-block;
            position: relative;
            top: -4px;
            margin-right: 8px;
            width: 4px;
            height: 4px;
            background-color: #585a5f;
            border-radius: 50%;
          }
        }
        .def {
          font-size: 16px;
          font-weight: 300;
          .textBox {
            margin-top: 8px;
          }
          .defExplain {
            margin-top: 8px;
            &:first-child {
              margin-top: 0;
            }
          }
        }
        & + .answer {
          margin-top: 20px;
        }
      }
      .textBox {
        margin-top: 6px;
        width: 100%;
        .textWrap {
          margin-top: 8px;
          &:first-child {
            margin-top: 0;
          }
          .iconEqual,
          .iconArrow {
            margin-right: 3px;
            margin-left: 3px;
            vertical-align: middle;
          }
          .true {
            color: #006ac3;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 1.4;
            vertical-align: middle;
          }
          .false {
            color: #eb4d59;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 1.4;
            text-decoration: line-through;
            vertical-align: middle;
          }
          .normal {
            color: #4b4b4b;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 1.4;
            vertical-align: middle;
          }
        }
        & + .answer {
          margin-top: 12px;
        }
        &.addressTextBox {
          width: 460px;
        }
      }
      .likeBtn {
        margin-top: 10px;
      }
    }
  }
  .dropHeader {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .dropTitleWrap {
      .dropTitle {
        display: inline-block;
        color: #000;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: -0.88px;
        vertical-align: middle;
      }
      .dropTitleExplain {
        display: inline-block;
        position: relative;
        top: 4px;
        margin-left: 13px;
        color: #9a9a9a;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.68px;
        &.executiveWarn {
          margin-left: 10px;
          color: #fd6200;
        }
      }
    }
    & + .dropDown {
      margin-top: 16px;
    }
  }
  .dropDown {
    margin-top: 8px;
  }
  .stockholderAddBtn {
    width: 100%;
    height: 100px;
    background-color: transparent;
    border: 1px dashed #999fac;
    border-radius: 4px;
    color: #999fac;
    font-size: 25px;
    font-weight: 700;
    .addGray {
      position: relative;
      top: -2px;
      margin-left: 5px;
      vertical-align: middle;
    }
  }
}
