.checkHeader {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  .checkAll {
    top: 1px;
  }
  .unCheckAll {
    font-size: 16px;
    font-weight: 500;
  }
}

.branchAddressList {
  margin-top: 16px;
  border-top: 2px solid #999fac;
  border-bottom: 2px solid #999fac;
  li {
    padding: 23px 18px;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.54px;
    border-top: 1px solid #c6cede;
    &:first-child {
      border-top: none;
    }
    &.removeLi {
      background-color: #fdedee;
    }
  }
}
