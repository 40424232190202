.placeholder {
  :global .text {
    &::placeholder {
      font-size: 18px !important;
    }
  }
}

.flex {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;

  .a1Width {
    display: inline-block;
    width: 56px;
  }
  .a2Width {
    display: inline-block;
    width: 83px;
  }
  .falseWidth {
    width: 157px;
  }
}

.textBoxWrap {
  .textBoxExplain {
    margin-top: 5px;
    color: #6c6c6c;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.88px;
    line-height: 1.4;
  }
  & + .answer {
    margin-top: 18px !important;
  }
}
