.inputWrap > .text[data-error='error'] {
  margin-bottom: 27px;
  width: 110%;
  outline: 1px solid #eb4d59;
  border: 1px solid #eb4d59;
  border-radius: 2px;
  background-color: rgba(235, 77, 89, 0.1);
  background-image: url('../../../asset/icon/ico_error.svg');
  background-size: 27px 23px;
  background-repeat: no-repeat;
}

.inputWrap > .text[data-error='error']:focus {
  outline: 1px solid #eb4d59;
  border: 1px solid #eb4d59;
}

.inputWrap > .text[data-error='error'].errorFixed {
  margin-bottom: 0;
}

.inputWrap > .text[data-error='error'].errorIconNon {
  padding-left: 18px;
  background-image: none;
}

.inputWrap > .kssn[data-error='error'] {
  margin-bottom: 27px;
  width: 110%;
  border: 2px solid #eb4d59;
  border-radius: 2px;
  background-color: rgba(235, 77, 89, 0.1);
  background-image: url('../../../asset/icon/ico_error.svg');
  background-size: 27px 23px;
  background-repeat: no-repeat;
}

.inputWrap > .kssn[data-error='error'].errorFixed {
  margin-bottom: 0;
}

.inputWrap > .left[data-error='error'] {
  padding-right: 63px;
  background-position: right 18px top 17px;
}

.inputWrap > .right[data-error='error'] {
  padding-left: 63px;
  background-position: left 18px top 15px;
}
