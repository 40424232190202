.table {
  width: 100%;
  thead {
    tr {
      th {
        background-color: #f6f5f5;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.48px;
        &:nth-child(1) {
          padding: 5px 6px 6px 12px;
          .check {
            div {
              &:before {
                top: 0;
              }
            }
          }
        }
        &:nth-child(2) {
          padding: 5px 0 6px 12px;
          text-align: left;
          .check {
            div {
              &:before {
                top: 10px;
              }
            }
            &.disabled {
              div {
                cursor: url('/images/sealCursorInfo.png'), not-allowed;
              }
            }
          }
        }
        &:nth-child(3) {
          padding: 5px 12px 6px 33px;
          text-align: left;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        color: #000;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.54px;
        border-bottom: 1px solid #c6cede;
        &:nth-child(1) {
          padding: 14px 6px 14px 12px;
          text-align: center;
          .check {
            div {
              padding-left: 0;
              &:before {
                position: relative;
                top: 2px;
              }
            }
          }
        }
        &:nth-child(2) {
          padding: 14px 0 14px 6px;
          text-align: center;
          .check {
            div {
              padding-left: 0;
              &:before {
                position: relative;
                top: 2px;
              }
            }
            &.disabled {
              div {
                cursor: url('/images/sealCursorInfo.png'), not-allowed;
              }
            }
          }
        }
        &:nth-child(3) {
          padding: 14px 12px 14px 0;
          .index {
            display: inline-block;
            margin-right: 3px;
            width: 30px;
          }
          .position {
            margin-left: 8px;
            color: #777c86;
            font-weight: 400;
          }
        }
      }
    }
  }
  tfoot {
    tr {
      td {
        padding-top: 12px;
        .checkState {
          display: flex;
          align-items: center;
          .lack {
            padding: 17px 30px;
            width: 172px;
            height: 58px;
            color: #eb4d59;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: -0.5px;
            background-color: #fdedee;
            border: 2px solid #eb4d59;
            border-radius: 2px;
            background-image: url('/images/ico_warning.svg');
            background-position: right 27px top 16px;
            background-repeat: no-repeat;
          }
          .enough {
            padding: 17px 30px;
            width: 172px;
            height: 58px;
            color: #0fac88;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: -0.5px;
            background-color: #e6f7f3;
            border: 2px solid #0fac88;
            border-radius: 2px;
            background-image: url('/images/ico_enough.svg');
            background-position: right 27px top 14px;
            background-repeat: no-repeat;
          }
          .explain {
            position: relative;
            padding-left: 16px;
            margin-left: 12px;
            color: #404040;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.4;
            letter-spacing: -0.9px;
            .bold {
              font-weight: 500;
            }
            &:before {
              content: '※';
              display: inline-block;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}
