@import '@pages/QuestionHeader.module.scss';

.balanceContent {
  .badge {
    margin-bottom: 10px;
    width: 100%;
    height: 570px;
    background-color: #ecf0f8;
    .badgeImg {
      display: inline-block;
      position: relative;
      top: 8px;
      left: 8px;
    }
    .badgeText {
      display: inline-block;
      position: relative;
      left: 28px;
      top: 22px;
      ul {
        li {
          position: relative;
          margin-top: 8px;
          padding-left: 18px;
          &:first-child {
            margin-top: 0;
          }
          color: #222;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: -0.55px;
          .bold {
            color: #006ac3;
            font-weight: 500;
          }
          &:before {
            content: '※';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
  .fileUpload {
    .bold {
      cursor: pointer;
      color: #006ac3;
      font-weight: 700;
    }
  }
  .textWrap {
    color: #6c6c6c;
    line-height: 1.33;
  }
}
