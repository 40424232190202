.titleWrap {
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;
    gap: 2px;

    img {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }

    color: #777c86;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px; /* 194.444% */
    letter-spacing: -1.25px;

    .strong {
      color: #2e2e2e;
    }

    .count {
      margin-left: 4px;
      font-weight: 400;
    }

    .arrowRightIcon {
      width: 16px;
      height: 16px;
      margin: 0 2px;
    }
  }

  .upDownButton {
    width: 22px;
    height: 22px;
    padding: 0;

    border: none;
    background-position: center;
    background-color: transparent;
    background-image: url('../../../../../../../../../../common/components/asset/icon/ico_up.svg');
    background-repeat: no-repeat;

    &.open {
      transform: scaleY(-1);
    }
  }
}

.divider {
  border-top: 1px solid #c6cbd5;
}

.purpose {
  display: flex;
  align-items: center;
  gap: 4px;

  color: #404040;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  letter-spacing: -1.25px;

  &.medium {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 500;
    line-height: 35px; /* 194.444% */
    letter-spacing: -1.25px;
  }
}

.highlight {
  color: #006AC3
}
