.passwordWrap {
  background-image: url('/images/passwordWrap.png');
  width: 100%;
  height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  > .content {
    width: 1240px;
    height: 730px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(81, 96, 160, 0.2);
    display: flex;
    .Advertisement {
      width: 414px;
      height: 100%;
      background-image: url('/images/passwordAdvertisement.png');
      background-size: cover;
      position: relative;
      .text {
        position: absolute;
        top: 131px;
        left: 57px;
        font-size: 28px;
        font-weight: 500;
        line-height: 1.29;
        letter-spacing: -1.62px;
        color: #2e2e2e;
        .thin {
          font-weight: 300;
        }
      }
      .logo {
        position: absolute;
        top: 30px;
        right: 30px;
      }
    }
    .password {
      display: flex;
      justify-content: center;
      flex: 1;
      .content {
        .logo {
          margin-top: 80px;
          text-align: center;
        }
        .title {
          margin-top: 8px;
          font-weight: 500;
          font-size: 32px;
          line-height: 1.44;
          letter-spacing: -1.6px;
          color: #2e2e2e;
        }
        .subTitle {
          font-size: 20px;
          font-weight: 500;
          line-height: 1.3;
          letter-spacing: -1px;
          color: #696969;
          margin-top: 16px;
        }
        .hintList {
          color: #696969;
          font-size: 16px;
          font-weight: 300;
          margin-top: 7px;
          li {
            padding-left: 10px;
            position: relative;
            letter-spacing: -0.64px;
            line-height: 1.5;
            margin-top: 2px;
            &:before {
              content: '';
              position: absolute;
              display: inline-block;
              top: 10px;
              left: 0;
              width: 4px;
              height: 4px;
              background-color: #696969;
              border-radius: 50%;
            }
          }
          margin-bottom: 33px;
        }
        .row {
          margin-top: 32px;
        }
        .submit {
          width: 100%;
          height: 58px;
          margin-top: 62px;
          background-color: #006ac3;
          color: #fff;
          font-size: 24px;
          font-weight: 400;
          line-height: 1.13;
          letter-spacing: -0.6px;
          &:disabled {
            background-color: #c6cbd5;
            cursor: not-allowed;
          }
          span {
            padding-right: 35px;
            background-image: url('/images/passwordSubmitIcon.svg');
            background-repeat: no-repeat;
            background-position: right;
          }
        }
      }
      &.hasPassword {
        .content {
          .logo {
            margin-top: 104px;
          }
          .subTitle {
            margin-top: 8px;
          }
          .hintList {
            display: none;
          }
          .row {
            margin-top: 82px;
            position: relative;
          }
          .submit {
            margin-top: 119px;
          }
        }
        .forgetPassword {
          cursor: pointer;
          position: absolute;
          background-color: transparent;
          right: 0;
          top: -1px;
          color: #696969;
          font-weight: 300;
          font-size: 14px;
          letter-spacing: -0.7px;
          z-index: 500;
        }
      }
    }
  }
}
