.dateTable {
  margin-top: 10px;
  width: 350px;
  color: #555;
  font-weight: 300;
  th {
    height: 38px;
    background-color: #f6f5f5;
    font-size: 14px;
    font-weight: 400;
    padding: 0 12px;
    line-height: 1.12;
  }
  td {
    font-size: 12px;
    line-height: 1.5;
    padding: 0 12px;
    border-bottom: 1px solid #d8d8d8;
  }
}
