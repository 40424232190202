.sortableItem {
  position: relative;
  width: 470px;
  padding: 13px 16px;
  border: 1px solid #d3dae2;
  background: #f4f5f7;
  display: flex;
  flex-direction: column;
  margin-left: 36px;
  min-height: 58px;
  box-sizing: border-box;
  &.chooseOwnPurposeItem {
    background-color: white;
    &::before {
      background-color: #b8b9bb;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: #00b4e3;
  }
  .purposeItemContainer {
    display: flex;
    align-items: center;
  }
  .businessPurposeNumber {
    color: #585a5f;
    font-size: 18px;
    font-weight: 400;
    line-height: 35px; /* 194.444% */
    letter-spacing: -1.25px;
    margin-right: 4px;
    &.businessPurposeNumber::selection {
      background-color: transparent;
    }
  }
  .recommendPurposeWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .textRecommendPurpose {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #585a5f;
      font-size: 18px;
      font-weight: 400;
      line-height: 35px; /* 194.444% */
      letter-spacing: -1.25px;
    }
    .errorBtnContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      width: 72px;
      height: 32px;
      cursor: pointer;
      border-radius: 4px;
      padding: 3px 10px;
      border: 1px solid #f80;
      background: #fdf8ed;
      .errorText {
        color: #f80;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.48px;
      }
      .errorIcon::selection,
      .errorText::selection {
        background-color: transparent;
      }
    }
  }
  .inputTextChooseOwnPurpose {
    background-color: transparent;
    width: 390px;
    height: 36px;
    border-bottom: 1px solid #b8b9bb;
    resize: none;
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 400;
    line-height: 35px; /* 194.444% */
    letter-spacing: -1.25px;
  }
  .dragging::selection {
    background-color: transparent;
  }
  .closeBtn {
    cursor: pointer;
    position: absolute;
    top: 18px;
    right: -36px;
    &.closeBtn::selection {
      background-color: transparent;
    }
  }
  .sortBtn {
    position: absolute;
    top: 24.5px;
    left: -36px;
  }
}
.errorArea {
  margin-left: 17px;
  padding-top: 2px;
  .errorText {
    color: #eb4d59;
    font-family: 'Noto Sans KR';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.48px;
    &.errorText::selection {
      background-color: transparent;
    }
  }
}
