.branchOfficeInstall {
  margin-top: 10px;
  font-weight: 500;
  line-height: 1.3;
  .installInfoBox {
    display: block;
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
    &:nth-child(even) {
      padding: 12px 0;
      background-color: #f4f5f7;
    }
    .installBranchInfo {
      display: flex;
      .dName {
        width: 140px;
        color: #555;
        font-weight: 400;
      }
      .data {
        .addressCopyBtn {
          margin-left: 8px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    .installBranchManagerInfo {
      display: flex;
      margin-top: 3px;
      .dName {
        width: 140px;
        color: #555;
        font-weight: 400;
      }
      .data {
        .changeInfoDetail {
          display: flex;
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
        }
        .addressCopyBtn {
          margin-left: 8px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
