.directorBox {
  position: relative;
  margin-top: 59px;
  width: 100%;
  .unCheck {
    position: absolute;
    top: -29px;
    right: 0;
    .unCheckBtn {
      font-weight: 500;
      &:active {
        position: relative;
        top: 1px;
        left: 1px;
      }
    }
  }
  .directorList {
    border-top: 2px solid #999fac;
    border-bottom: 2px solid #999fac;
    li {
      border-top: 1px solid #c6cede;
      &:first-child {
        border-top: none;
      }
      .director {
        padding: 23px 18px;
      }
    }
  }
}
