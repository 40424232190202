.parValueTable {
  margin-top: 24px;
  width: 100%;
  thead {
    tr {
      th {
        padding: 10px 0 11px;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.48px;
        background-color: #f6f5f5;
        &:nth-child(1) {
          padding-left: 12px;
          padding-right: 12px;
          text-align: left;
        }
        &:nth-child(2) {
          padding-left: 31px;
          padding-right: 24px;
          text-align: left;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 14px 0;
        border-bottom: 1px solid #c6cede;
        color: #000;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.54px;
        &:nth-child(1) {
          padding-left: 12px;
          padding-right: 12px;
        }
        &:nth-child(2) {
          padding-left: 31px;
          color: #006ac3;
          line-height: 26px;
          background-image: url('/images/ico_triangle_arrow.svg');
          background-repeat: no-repeat;
          background-position: center left 6px;
        }
      }
      &:last-child {
        td {
          border-bottom: 2px solid #999fac;
        }
      }
    }
  }
}
