.finalEstimate {
  .title {
    color: #000;
    font-size: 24px;
    font-weight: 500;
    line-height: 0.92;
    letter-spacing: -0.96px;
  }
  .estimateTable {
    margin-top: 24px;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    tbody {
      display: inline-block;
      min-height: 355px;
      vertical-align: top;
      tr {
        th {
          padding: 9px 0;
          width: 180px;
          color: #777c86;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: -0.64px;
          text-align: left;
        }
        td {
          width: 180px;
          color: #777c86;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: -0.64px;
          text-align: right;
        }
      }
    }
    .utility {
      tr {
        &:nth-child(1) {
          border-bottom: 1px solid #c6cede;
          th {
            padding: 20px 20px 20px 0;
            color: #000;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.1;
            letter-spacing: -0.8px;
          }
          td {
            color: #006ac3;
            font-size: 16px;
            font-weight: 400;
          }
        }
        &:nth-child(2) {
          th,
          td {
            padding: 20px 20px 8px 0px;
          }
        }
        &:last-child {
          th,
          td {
            padding: 8px 20px 20px 0px;
          }
        }
        td {
          padding-right: 20px;
        }
      }
    }
    .commission {
      border-left: 1px solid #c6cede;
      tr {
        &:nth-child(1) {
          border-bottom: 1px solid #c6cede;
          th {
            padding: 20px 0 20px 20px;
            color: #000;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.1;
            letter-spacing: -0.8px;
          }
          td {
            color: #006ac3;
            font-size: 16px;
            font-weight: 400;
          }
        }
        &:nth-child(2) {
          th,
          td {
            padding: 20px 0 8px 20px;
          }
        }
        &:last-child {
          th,
          td {
            padding: 8px 0 20px 20px;
          }
        }
        th {
          padding-left: 20px;
        }
      }
    }
  }
}
