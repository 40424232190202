@import '@pages/ModalContent/ModalContent.module.scss';

.Modal {
  .title {
    width: 325px;
  }
  .modalContent {
    width: 410px;
  }
}
