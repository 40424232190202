.freeIssuedOfNewShares {
  margin-top: 9px;
  .freeIssuedOfNewSharesTitle {
    color: #2e2e2e;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.41;
    letter-spacing: -0.34px;
  }
  .freeIssuedOfNewSharesContent {
    margin-top: 2px;
    color: #585a5f;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.74px;
  }
}

.checkHeader {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  .checkAll {
    top: 1px;
  }
  .unCheckAll {
    font-size: 16px;
    font-weight: 500;
  }
}

.resourceInfoList {
  margin-top: 16px;
  border-top: 2px solid #999fac;
  border-bottom: 2px solid #999fac;
  li {
    padding: 23px 18px;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.54px;
    border-top: 1px solid #c6cede;
    &:first-child {
      border-top: none;
    }
    &.checkLi {
      background-color: #e5f0f9;
    }
  }
}

.recordDateBadge {
  margin-bottom: 18px;
  position: relative;
  width: 100%;
  height: 482px;
  background-color: #d3dae2;
  .recordDateBadgeBox {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 544px;
    height: 466px;
    background-color: #fff;
    .recordDateBadgeImg {
      display: inline-block;
      position: absolute;
      top: 18px;
      left: 27px;
    }
    .recordDateBadgeTextBox {
      position: absolute;
      top: 262px;
      left: 27px;
      width: 492px;
      .recordDateBadgeText {
        position: relative;
        padding-left: 19px;
        color: #404040;
        font-size: 15px;
        line-height: 1.4;
        letter-spacing: -0.75px;
        .text {
          margin-top: 3px;
          color: #006ac3;
          &:first-child {
            margin-top: 0;
          }
          .bold {
            font-weight: 700;
          }
          &.colorChange {
            color: #404040;
          }
        }
        &:before {
          display: inline-block;
          position: absolute;
          left: -1px;
          top: 3px;
          content: '';
          width: 16px;
          height: 16px;
          background-image: url('/images/registrationIcon.svg');
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      .recordDateBadgeTextExplain {
        position: relative;
        margin-top: 15px;
        padding-left: 19px;
        color: #404040;
        font-size: 14px;
        line-height: 1.4;
        letter-spacing: -0.75px;
        .bold {
          font-weight: 700;
        }
        &:before {
          display: inline-block;
          content: '※';
          position: absolute;
          left: 0;
        }
      }
    }
  }
}

.freeIssuedOfNewSharesAmountAndNumberBox {
  position: relative;
  width: 100%;
  height: 394px;
  border-radius: 4px;
  background-color: #ecf0f8;
  .freeIssuedOfNewSharesAmountAndNumberImg {
    position: absolute;
    top: 8px;
    left: 8px;
    display: inline-block;
    width: 544px;
    height: 378px;
  }
}

.freeIssuedOfNewSharesAmountAndNumberInputBox {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  .inputBox {
    display: flex;
    .amountBox,
    .numberBox {
      &.amountBox {
        margin-right: 18px;
      }
      .title {
        color: #000;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.48px;
      }
      .input {
        margin-top: 16px;
      }
      .won {
        display: inline-block;
        margin-left: 8px;
        color: #000;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: -0.64px;
      }
    }
  }
  .countBox {
    text-align: right;
    .title {
      color: #000;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.48px;
    }
    .count {
      margin-top: 30px;
      color: #000;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.64px;
      .issuePriceError {
        color: #eb4d59;
      }
      .won {
        display: inline-block;
        margin-left: 8px;
        color: #000;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: -0.64px;
      }
      .successPrice {
        margin-top: 3px;
        color: #006ac3;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -0.6px;
      }
    }
  }
}
