.wrap {
  margin: 0 auto;
  width: 100%;
}
.viewArea {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.content {
  width: 350px;
  height: 100px;
  position: relative;
  overflow: hidden;
}

.content img {
  position: absolute;
}
.img1 {
  bottom: -1px;
  right: 204px;
  z-index: 1;
  transition-delay: 2s;
}
.img2 {
  bottom: 0px;
  right: 249px;
  transition-delay: 0.3s;
}
.img3 {
  bottom: 0px;
  right: 183px;
  transition-delay: 0.6s;
}
.img4 {
  bottom: -1px;
  right: 146px;
  transition-delay: 0.9s;
}
.img5 {
  bottom: -1px;
  right: 115px;
  transition-delay: 1.3s;
}
.img6 {
  bottom: -1px;
  right: 102px;
  z-index: 1;
  transition-delay: 1.5s;
}
.img7 {
  bottom: -1px;
  right: 65px;
  z-index: 1;
  transition-delay: 1.8s;
}
.img8 {
  bottom: -1px;
  right: 71px;
  transition-delay: 2.1s;
}
.img9 {
  bottom: -1px;
  right: 46px;
  z-index: 2;
  transition-delay: 2.4s;
}
.img10 {
  bottom: -1px;
  right: 86px;
  transition-delay: 2.7s;
}
.img11 {
  bottom: 44px;
  right: 20px;
  transition-delay: 4.5s;
  transform: translateX(20px);
}
.img12 {
  bottom: 17px;
  right: 299px;
  transition-delay: 4s;
  transform: translateX(-20px);
}
.img13 {
  right: 245px;
  bottom: -1px;
  transition-delay: 7.3s;
}
.img14 {
  right: 166px;
  bottom: 3px;
  transition-delay: 7s;
}
.img15 {
  right: 81px;
  bottom: 4px;
  transition-delay: 6.7s;
}
.img16 {
  bottom: 63px;
  right: 87px;
}
.content img.build {
  transition-duration: 1.3s;
  transform: translateY(100%);
  transition-timing-function: cubic-bezier(0.42, 2.51, 0.36, -0.98);
}
.content img.cloud {
  transition-duration: 1.3s;
  opacity: 0;
}
.content img.sun {
  bottom: -30px;
  transition-duration: 0.8s;
  transition-timing-function: linear;
  transition-delay: 5.3s;
}
.content img.shadow {
  opacity: 0;
  transition-duration: 1s;
}
.content.active img.sun {
  bottom: 63px;
  animation-timing-function: linear;
  animation-delay: 6.3s;
  animation-name: rotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
}
.content.active img.shadow {
  opacity: 1;
}
.content.active img.cloud {
  opacity: 1;
  transform: translateX(0px);
}
.content.active img.build {
  transform: translateY(0);
}

@keyframes rotate {
  form {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
