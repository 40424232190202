.titleWrap {
  .title {
    color: #2e2e2e;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: -1.04px;
    line-height: 1.46;
    .bold {
      color: #006ac3;
    }
  }
  .titleExplain {
    position: relative;
    padding-left: 17px;
    color: #777c86;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.88px;
    line-height: 1.4;
    width: 100%;
    &:before {
      content: '※';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 1px;
    }
  }
}

.sharesOnRegisterTable {
  margin-top: 20px;
  width: 100%;
  .thead {
    tr {
      height: 47px;
      background-color: #f6f5f5;
      th {
        color: #000;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.54px;
        text-align: center;
        &:first-child {
          padding: 0 0 0 34px;
          text-align: left;
        }
        &:last-child {
          padding: 0 34px 0 0;
          text-align: right;
        }
      }
    }
  }
  .tbody {
    tr {
      td {
        padding: 14px 0;
        color: #000;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: -0.8px;
        text-align: center;
        border-bottom: 1px solid #c6cede;
        &:first-child {
          padding-left: 34px;
          text-align: left;
        }
        &:last-child {
          padding-right: 34px;
          text-align: right;
        }
      }
    }
  }
}

.limitedLiabilityTable {
  margin-top: 20px;
  width: 605px;
  border-top: 2px solid #999fac;
  border-bottom: 1px solid #c6cede;
  tr {
    th {
      padding-left: 34px;
      height: 47px;
      color: #000;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.54px;
      text-align: left;
    }
    td {
      padding-right: 34px;
      height: 47px;
      color: #000;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.54px;
      text-align: right;
    }
  }
}

.newSharesTable,
.limitedNewSharesTable {
  margin-top: 24px;
  width: 100%;
  &.limitedNewSharesTable {
    width: 605px;
    .tbody {
      tr {
        td {
          &:last-child {
            text-align: right !important;
          }
        }
      }
    }
  }
  .thead {
    tr {
      height: 38px;
      background-color: #999fac;
      th {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.54px;
        &:last-child {
          padding-right: 34px;
          text-align: right;
        }
      }
    }
  }
  .tbody {
    tr {
      &.odd {
        td {
          background-color: #f6f5f5;
        }
      }
      &.even {
        td {
          background-color: #fff;
        }
      }
      &.second {
        td {
          padding: 8px 0 34px;
        }
      }
      &.sumRow {
        td {
          padding-top: 12px;
          border-top: 1px solid #999fac;
          color: #006ac3;
          font-size: 18px;
          &:nth-child(n + 3):nth-child(-n + 4) {
            padding-right: 66px;
            text-align: right;
            .tagText {
              display: inline-block;
              margin-left: 18px;
            }
          }
        }
      }
      td {
        padding: 34px 0 34px 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.3;
        vertical-align: center;
        &:first-child {
          padding-left: 34px;
          font-size: 20px;
          letter-spacing: -0.6px;
          text-align: left;
        }
        &:nth-child(2) {
          font-size: 18px;
          letter-spacing: -0.54px;
          text-align: center;
        }
        &:nth-child(n + 3):nth-child(-n + 4) {
          text-align: center;
          .text {
            display: inline-block;
            margin-left: 4px;
          }
        }
        &:last-child {
          padding-right: 34px;
          text-align: right;
        }
        .issuePriceError {
          color: #eb4d59;
        }
      }
    }
  }
}

.rowBtn {
  height: 40px;
  position: relative;
  top: -2px;
  width: 38px;
  color: #fff;
  background-color: #006ac3;
  margin-left: 5px;
  font-size: 14px;
  &.delete {
    background-color: #eb4d59;
  }
}
.explainBox {
  background-color: #ecf0f8;
  padding: 8px;
  justify-content: space-between;
  display: flex;
  margin-top: 24px;
  margin-bottom: 4px;
  .exContent {
    background-color: #fff;
    width: 100%;
    margin-left: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 25px;
    &:nth-child(1) {
      margin-left: 0;
    }
    display: flex;
    > .title {
      color: #006ac3;
      font-size: 20px;
      font-weight: 500;
      margin-right: 30px;
      word-break: keep-all;
    }
    > .content {
      > .title {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.33;
        margin-top: 6px;
        &:nth-child(1) {
          margin-top: 0;
        }
        .link {
          text-decoration: underline;
          color: #006ac3;
        }
      }
      > .content {
        font-size: 15px;
        font-weight: 300;
        margin-top: 2px;
        line-height: 1.63;
      }
    }
  }
}
.miniInput {
  height: 40px !important;
  font-size: 16px !important;
  background-position: right 18px top 8px !important;
  &::placeholder {
    font-size: 16px !important;
  }
}
.miniSelect {
  height: 40px !important;
  padding: 0 !important;
  padding-left: 14px !important;
  font-size: 16px !important;
  background-position: right 18px top 9px !important;
}
.successPrice {
  color: #006ac3;
  font-size: 15px;
}
