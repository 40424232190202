.container {
  display: flex;
  padding: 20px 24px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 8px;
  background: #fff;
  border: 1.5px solid #d8d8d8;

  &.selected {
    border-color: #006ac3;
  }

  .titleWrap {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .purposesWrap {
    display: flex;
    width: 632px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 14px;
    flex-wrap: wrap;
  }

  .purpose {
    display: flex;
    width: 309px;
    align-items: center;
  }
}
