.corpTaxAdvertisement {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  width: 1240px;
  height: 209px;
  padding: 58px 45px;
  margin: 0 auto;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px 0 rgba(81, 96, 160, 0.15);
  .advertisement {
    color: #000;
    font-size: 32px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -1.6px;
    .bold {
      display: inline-block;
      padding: 0 8px;
      background-color: #006ac3;
      color: #fff;
    }
  }
  .advertisementSub {
    margin-top: 5px;
    color: #000;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: -0.96px;
    .bold {
      font-weight: 500;
    }
  }
  .accountantFirst {
    position: absolute;
    right: 315px;
    bottom: 0;
    width: 181px;
    height: 196px;
  }
  .accountantSecond {
    position: absolute;
    right: 135px;
    bottom: 0;
    width: 181px;
    height: 196px;
  }
  .accountantFirstTag,
  .accountantSecondTag {
    position: absolute;
    bottom: 8px;
    color: #333;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.9px;
    &.accountantFirstTag {
      right: 486px;
      text-align: right;
    }
    &.accountantSecondTag {
      right: 64px;
    }
  }
}

.titleExplain {
  .aLink {
    color: #006ac3;
    text-decoration: underline;
  }
}

.radioLabelExplain {
  color: #585a5f;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.4px;
}

.corporateTaxImg {
  margin-top: 50px;
  width: 560px;
  height: 775px;
}

.keyWordExplain {
  margin-top: 9px;
  .keyWord {
    position: relative;
    padding-left: 12px;
    color: #2e2e2e;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.41;
    letter-spacing: -0.34px;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 10px;
      left: 0;
      width: 4px;
      height: 4px;
      background-color: #2e2e2e;
      border-radius: 50%;
    }
  }
  .explain {
    color: #585a5f;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.8px;
    line-height: 1.5;
  }
}
