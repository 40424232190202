.container {
  flex: 1;

  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;

  .header {
    margin: 12px 24px;

    color: #777c86;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -1.25px;

    span {
      color: #2e2e2e;
    }

    .divider {
      margin-top: 8px;
      border-top: 1px solid #c6cbd5;
      width: 100%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    height: calc(100% - 58px);
    overflow-y: scroll;

    padding: 0 24px 12px;
  }

  .footer {
    // position: absolute;
    // bottom: 0;

    border-top: 1px solid #d8d8d8;
    background: transparent;

    button {
      width: 100%;
      height: 58px;
      background: transparent;

      color: #006ac3;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 29px; /* 145% */
      letter-spacing: -0.48px;
    }
  }
}
