.btnArea {
  position: relative;
  .btnBox {
    position: absolute;
    right: 0;
    bottom: -15px;
    .managerModeBtn,
    .estimateBtn {
      padding: 0 19px;
      height: 28px;
      font-size: 13px;
      letter-spacing: -0.65px;
      border: none;
      &.managerModeBtn {
        background-color: #006ac3;
        color: #fff;
      }
      &.estimateBtn {
        margin-left: 12px;
        border: 1px solid #006ac3;
        background-color: #fff;
        color: #006ac3;
      }
    }
    .causeCopyBtn {
      display: inline-block;
      margin-right: 12px;
      font-size: 13px;
      letter-spacing: -0.65px;
    }
  }
}

.table {
  margin-top: 16px;
  font-size: 13px;
  letter-spacing: -0.5px;
  &:nth-child(2) {
    margin-top: 0;
  }
  .title {
    padding-bottom: 9px;
    color: #2e2e2e;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.55px;
    border-bottom: 1px solid #2e2e2e;
  }
  .info {
    .row {
      display: flex;
      align-items: center;
      min-height: 19px;
      margin-top: 6px;
      &:first-child {
        margin-top: 10px;
      }
      &.shareholdersRow,
      &.executiveRow,
      &.businessPurposeRow {
        margin-top: 12px;
        align-items: inherit;
        &.shareholdersRow {
          margin-top: 8px;
        }
      }
      &.corpSealRow {
        margin-top: 8px;
        align-items: inherit;
      }
      .dName {
        position: relative;
        width: 140px;
        flex-shrink: 0;
        color: #555;
        font-weight: 300;
        .reviseBtn {
          position: absolute;
          top: -1px;
          right: 25px;
          background-color: transparent;
        }
      }
      .data {
        font-weight: 500;
        .alarmText {
          color: #999fac;
          font-size: 13px;
          font-weight: 400;
          letter-spacing: -0.5px;
        }
        .shareholder,
        .executive {
          display: flex;
          width: 787px;
          padding-top: 8px;
          padding-bottom: 6px;
          border-top: 1px solid #c6cbd5;
          &:first-child {
            padding-top: 0;
            margin-top: 0;
            border: none;
          }
          .shareholderPosition {
            position: relative;
            top: -3px;
            min-width: 40px;
            flex-shrink: 0;
          }
          .executivePosition {
            position: relative;
            top: -3px;
            min-width: 48px;
            flex-shrink: 0;
            line-height: 1.1;
          }
          .shareholderInfo,
          .executiveInfo {
            position: relative;
            margin-left: 30px;
            &.shareholderInfo {
              top: -3px;
            }
            &.executiveInfo {
              top: -3px;
            }
            .person {
              span {
                &:before {
                  content: '';
                  display: inline-block;
                  position: relative;
                  top: 3px;
                  margin: 0 14px;
                  width: 2px;
                  height: 15px;
                  border-radius: 50px;
                  background-color: #999fac;
                }
                &:first-child:before {
                  content: none;
                }
                &:nth-child(n + 3):nth-child(-n + 4) {
                  font-weight: lighter;
                }
              }
              .personType {
                display: inline-block;
                padding: 2px 9px 0;
                height: 18px;
                margin-left: 13px;
                background-color: #006ac3;
                color: #fff;
                font-weight: normal;
                letter-spacing: -0.6px;
              }
              .resignation {
                color: #555;
                font-size: 13px;
              }
              .shareholderCopyBtn,
              .executiveCopyBtn {
                margin-left: 8px;
                &:first-child {
                  margin-left: 0;
                }
              }
            }
            .address {
              margin-top: 4px;
              font-weight: lighter;
            }
            .positionInfo {
              margin-top: 6px;
              color: #999fac;
              font-size: 13px;
              font-weight: 300;
              .star {
                position: relative;
                top: 4px;
              }
            }
            .representativeInfo {
              display: inline-flex;
              margin-top: 4px;
              font-weight: lighter;
              .representativeTitle {
                position: relative;
                top: 3px;
                margin-right: 8px;
              }
            }
          }
        }

        .businessPurposeWrapper {
          width: 100%;
          border-bottom: 1px solid #c6cbd5;
          margin-bottom: 10px;
          min-height: 33px;
          padding-bottom: 10px;
        }
        .businessPurposeBox {
          display: flex;
          .purposeTitleWrapper {
            width: 120px;
            .purposeTitle {
              display: inline-block;
              padding: 4px;
              border: 1px solid #c6cbd5;
              font-size: 13px;
              font-weight: 400;
              letter-spacing: -0.5px;
            }
          }
        }
        .purposeExplainText {
          margin-left: 120px;
          font-size: 13px;
          font-weight: 400;
          letter-spacing: -0.5px;
          color: #555;
        }
        .btnGap {
          margin-right: 10px;
        }
        &.businessPurpose {
          margin-left: -20px;
          margin-top: -4px;
          .purpose {
            display: inline-block;
            margin-left: 20px;
            line-height: 1.75;
          }
        }
        .stampData {
          margin-top: 6px;
          &:first-child {
            margin-top: 0;
          }
        }
        .stampSymbol {
          display: inline-block;
          margin-left: 3px;
          padding: 0 2px 0 3px;
          border: 1px solid #bcbcbc;
          font-size: 13px;
          line-height: 1.45;
        }
        .addressCopyBtn {
          margin-left: 8px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
      .stockOptionCopyBtn {
        margin-left: 7px;
        font-size: 14px;
        &:first-child {
          margin-left: 8px;
        }
      }
      .addressCopyBracket {
        display: inline-block;
        margin-left: 7px;
      }
      .addressCopyAllBtn {
        margin-left: 7px;
        .copyIcon {
          position: relative;
          top: 3px;
        }
      }
      .balanceBankInfo {
        display: inline-block;
        width: 280px;
        height: 30px;
        padding: 0 8px;
        background-color: #fff;
        border: 1px solid #b5bfc4;
        color: #222;
        &:focus {
          border: 1px solid #006ac3;
        }
        &::placeholder {
          opacity: 0.3;
          color: #222;
        }
      }
    }
  }
  .balanceCertificateBtnArea {
    position: relative;
    .balanceCertificateChangeBtn {
      position: absolute;
      left: 140px;
      padding: 0 9px 1px;
      height: 18px;
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      background-color: #006ac3;
    }
  }
}
