.Modal {
  .title {
    color: #000;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: -0.88px;
    .bold {
      color: #006ac3;
    }
  }
  .modalContent {
    margin-top: 26px;
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.56;
    letter-spacing: -0.45px;
    .content {
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
