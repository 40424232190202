.react-datepicker__tab-loop {
  display: inline-block;
}
.react-datepicker__input-container input.datePicker {
  background: #fff;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid #b5bfc4;
  padding-left: 8px;
  padding-right: 8px;
  width: 79px;
  font-size: 13px;
  letter-spacing: -0.52px;
  color: #222;
  border-color: #b5bfc4;
}
.react-datepicker__input-container input.datePicker:focus {
  border-color: #006ac3;
}
.react-datepicker__input-container input.datePicker:disabled {
  background: #f3f3f3;
  cursor: not-allowed;
}
.react-datepicker__input-container input.datePicker:disabled::placeholder {
  opacity: 0.3;
}
.react-datepicker__input-container input.datePicker::placeholder {
  font-size: 13px;
  opacity: 0.3;
  color: #222;
  letter-spacing: -0.52px;
}
