@import '@pages/ModalContent/ModalContent.module.scss';

.modalContent {
  width: 720px;
  text-align: center;
  .title {
    color: #2e2e2e;
    font-size: 30px;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: -0.72px;
    .bold {
      color: #006ac3;
      font-weight: 700;
    }
  }
  .steps {
    display: flex;
    margin: 0 auto;
    margin-top: 10px;
    width: 720px;
    .stepWrap {
      flex: 1;
      display: inline-flex;
      justify-content: center;
      &:nth-child(1) {
        .step {
          padding-left: 40px;
          background-image: url('/images/step1.png');
        }
      }
      &:nth-child(2) {
        .step {
          padding-left: 42px;
          background-image: url('/images/step2.png');
        }
      }
      &:nth-child(3) {
        .step {
          padding-left: 42px;
          background-image: url('/images/step3.png');
        }
      }
      .step {
        background-repeat: no-repeat;
        background-position: top left;
        min-height: 80px;
        .stepContent {
          margin-top: 15px;
          color: #2e2e2e;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 1.38;
          letter-spacing: -0.48px;
          text-align: left;
          .bold {
            color: #006ac3;
          }
        }
      }
    }
  }
  .explain {
    margin-bottom: 30px;
    color: #777c86;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.64px;
  }
  .btnArea {
    display: flex;
    .directBtn {
      width: 278px;
      height: 200px;
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      border: 1px solid #c6cbd5;
      background-color: #fff;
      color: #2e2e2e;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: -0.6px;
      &:active {
        background-color: #e5f0f9;
      }
      .text {
        display: inline-block;
        padding-top: 80px;
        background-image: url('/images/stockholderMeetingDirect.svg');
        background-repeat: no-repeat;
        background-position: top center;
      }
    }
    .serviceBtn {
      position: relative;
      margin-left: 24px;
      width: 418px;
      height: 200px;
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      border: 1px solid #006ac3;
      background-color: #fff;
      color: #2e2e2e;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: -0.6px;
      &:active {
        background-color: #e5f0f9;
      }
      .text {
        display: inline-block;
        padding-top: 81px;
        background-image: url('/images/stockholderMeetingService.svg');
        background-repeat: no-repeat;
        background-position: top center;
        .bold {
          color: #006ac3;
          font-weight: 700;
        }
      }
      .tag {
        position: absolute;
        top: -1px;
        right: -1px;
        display: inline-block;
        padding: 4px 0;
        width: 60px;
        height: 32px;
        border-radius: 4px;
        background-color: #006ac3;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.48px;
        text-align: center;
      }
      .btnExplain {
        position: absolute;
        bottom: -30px;
        left: 74px;
        color: #777c86;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: -0.64px;
      }
    }
  }
}
