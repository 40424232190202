.table {
  width: 560px;
  thead {
    tr {
      th {
        padding: 12px 0;
        background-color: #999fac;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.54px;
        &:nth-child(1) {
          padding-left: 37px;
          padding-right: 8px;
          text-align: left;
        }
        &:nth-child(2) {
          padding-left: 8px;
          padding-right: 8px;
        }
        &:nth-child(3) {
          padding-right: 40px;
          text-align: right;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 14px 0;
        border-bottom: 1px solid #c6cede;
        color: #000;
        font-size: 20px;
        font-weight: 500;
        &:nth-child(1) {
          padding-left: 8px;
          padding-right: 8px;
          text-align: left;
          font-weight: bold;
          .index {
            display: inline-block;
            margin-right: 3px;
            width: 30px;
          }
        }
        &:nth-child(2) {
          padding-left: 8px;
          padding-right: 8px;
          text-align: center;
        }
        &:nth-child(3) {
          padding-right: 8px;
          text-align: right;
          .text {
            margin-left: 4px;
          }
        }
      }
    }
  }
  tfoot {
    tr {
      td {
        padding-top: 14px;
        color: #006ac3;
        font-size: 20px;
        font-weight: 500;
        &:nth-child(1) {
          padding-left: 38px;
          text-align: left;
        }
        &:nth-child(2) {
          padding-right: 8px;
          text-align: right;
        }
      }
    }
  }
}
