.checkHeader {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.deleteAnswerList {
  margin-top: 16px;
  &.deleteCheckList {
    border-top: 2px solid #999fac;
    border-bottom: 2px solid #999fac;
    li {
      border-bottom: 1px solid #c6cede;
      height: 68px;
      box-sizing: border-box;
      padding-top: 23px;
      padding-right: 17px;
      padding-left: 18px;
      &:nth-last-child(1) {
        border-bottom: none;
      }
      &.removeLi {
        background-color: #fdedee;
      }
    }
  }
}
.squareBtn {
  display: block;
  margin-top: 8px;
  margin-bottom: 9px;
}
.answerList {
  margin-top: 16px;
  .businessPurposeAddBtn {
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: #999fac;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    padding: 19px 18px 20px;
    .addGray {
      position: relative;
      top: -2px;
      margin-left: 5px;
      width: 19px;
      height: 19px;
      vertical-align: middle;
    }
  }
}
li.helperClass {
  box-shadow: 5px 5px 8px 5px rgba($color: #000000, $alpha: 0.1);
  z-index: 999999;
}
li.sortableItem {
  position: relative;
  padding: 13px 17px 14px 18px;
  background-color: #fff;
  &.fixedText {
    padding-left: 54px;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -0.5px;
    height: 68px;
    line-height: 40px;
    .number {
      margin-right: 10px;
    }
  }
  &.checkedItem {
    background-color: rgba($color: #006ac3, $alpha: 0.1);
  }
  .checkBox {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .check {
      &:after {
        content: '';
        display: inline-block;
        width: 22px;
        height: 22px;
        background-image: url('/images/btn_check_s.svg');
        background-size: cover;
        background-repeat: no-repeat;
        vertical-align: top;
      }
    }
    &:checked {
      & ~ .inputText,
      & ~ label {
        color: #006ac3;
      }
    }
  }
  .check {
    cursor: pointer;
    display: inline-block;
    position: relative;
    top: 1px;
    margin-top: 9px;
    margin-right: 14px;
    width: 22px;
    height: 22px;
    background-image: url('/images/btn_check_n.svg');
    background-size: cover;
    background-repeat: no-repeat;
    vertical-align: top;
  }
  .inputText,
  .businessPurposeNumber {
    display: inline-block;
    height: 22px;
    color: #000;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -0.5px;
    vertical-align: top;
  }
  .businessPurposeNumber {
    margin-top: 10px;
    margin-right: 10px;
  }
  .inputText {
    margin-top: 5px;
    margin-right: 14px;
    width: 412px;
    background-color: transparent;
    height: 35px;
    resize: none;
  }
  .btnWrap {
    display: inline-block;
    position: relative;
    top: 1px;
    .plus {
      display: block;
    }
    .minus {
      display: block;
      margin-top: 2px;
    }
  }
  .sortBtn {
    display: inline-block;
    position: absolute;
    top: 27px;
    right: -32px;
  }
  // &:hover {
  //     .sortBtn {
  //         display: block;
  //     }
  // }
}
.purpose {
  li {
    position: relative;
    margin-top: 11px;
    margin-left: 10px;
    color: #404040;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.44;
    letter-spacing: -0.96px;
    &:first-child {
      margin-top: 0;
    }
    &:before {
      content: '-';
      display: inline-block;
      position: absolute;
      top: 0;
      left: -10px;
    }
    .purposeExplain {
      display: inline-block;
      color: #6c6c6c;
      font-size: 15px;
      font-weight: 400;
    }
  }
}
.defList {
  .textWrap {
    padding: 0 10px 18px;
    border-bottom: 1px solid #d3d3d3;
    .wrap {
      display: inline-block;
      text-align: center;
      .title {
        margin-bottom: 3px;
        color: #404040;
        font-size: 14px;
      }
      .content {
        color: #006ac3;
        font-size: 17px;
      }
    }
    .arrow {
      margin: 0 14px;
      position: relative;
      top: 5px;
    }
    .plusGray {
      margin: 0 15px 0 20px;
      position: relative;
      top: 1px;
    }
  }
  .exampleWrap {
    display: inline-flex;
    justify-content: space-between;
    margin-top: 18px !important;
    width: 100%;
    .area {
      display: inline-block;
      position: relative;
      top: 2px;
      color: #404040;
      font-size: 14px;
    }
    .areaExample {
      display: inline-block;
      width: 302px;
      color: #6c6c6c;
      font-size: 15px;
      .bold {
        font-size: 15px;
        color: #006ac3;
        font-weight: 500;
      }
    }
  }
}
.textBox {
  &.many {
    width: 100%;
    & > div {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      &:nth-last-child(2) {
        border-top: 1px solid #e9ecf0;
      }
      &:nth-last-child(1) {
        padding-top: 0;
      }
      .contentText {
        padding-top: 20px;
        border-top: 1px solid #d3d3d3;
      }
    }
  }
  &.first {
    div {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .contentText {
    font-size: 14px;
    font-weight: 400;
    .bold {
      font-weight: 500;
    }
  }
}
.flexText {
  &:nth-last-child(1) {
    border-top: 1px solid #d3d3d3;
    padding-top: 18px;
    margin-top: 0 !important;
  }
  &:nth-last-child(2) {
    padding-bottom: 18px;
  }
  display: flex;
  align-items: center;
  .iconArrow {
    margin: 0 9px !important;
  }
}
.titleInfo {
  font-size: 17px;
  line-height: 1.41;
  letter-spacing: -0.34px;
  font-weight: 400;
  margin-top: 4px;
  .bold {
    font-weight: 500;
    color: #006ac3;
  }
}
.infoTable {
  width: 100%;
  border-top: 1px solid #999fac;
  border-bottom: 1px solid #999fac;
  th {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    line-height: 1.43;
    background-color: #c6cede;
    border-top: 1px solid #fff;
  }
  td {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.47;
    letter-spacing: -0.3px;
    border-top: 1px solid #c6cede;
    .bold {
      font-weight: 500;
    }
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 25px;
  }
  tr {
    &:nth-child(1) {
      td,
      th {
        border-top: none;
      }
    }
  }
}
.priceTable {
  margin-top: 50px;
  width: 100%;
  border-top: 2px solid #999fac;
  border-bottom: 2px solid #999fac;
  th {
    padding: 16px 0;
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid #c6cede;
  }
  td {
    position: relative;
    font-size: 28px;
    font-weight: 400;
    padding: 30px 0;
    text-align: center;
    .sub {
      font-size: 16px;
      font-weight: 400;
    }
    &.important {
      color: #006ac3;
    }
  }
  .plus {
    position: absolute;
    top: 50%;
    margin-top: -13px;
    right: -23px;
    transform: rotate(45deg);
  }
  .result {
    position: absolute;
    top: 50%;
    margin-top: -13px;
    right: 10px;
  }
}
