.table {
  width: 750px;
  color: #555;
  th {
    font-size: 18px;
    font-weight: 400;
    background-color: #f6f5f5;
    height: 55px;
    line-height: 1.19;
    &:nth-child(1) {
      padding: 0 15px 0 20px;
    }
    &:nth-child(2) {
      padding: 0 20px 0 15px;
      font-size: 16px;
      letter-spacing: -0.8px;
    }
    &:nth-child(3) {
      padding: 0 30px;
    }
    &:nth-child(4) {
      padding: 0 20px;
    }
  }
  td {
    font-size: 16px;
    font-weight: 400;
    height: 50px;
    border-bottom: 1px solid #d8d8d8;
    &:nth-child(1) {
      padding: 0 15px 0 20px;
      font-size: 500;
      color: #000;
      font-size: 20px;
    }
    &:nth-child(2) {
      padding: 0 20px 0 15px;
    }
    &.sum {
      padding: 0 30px;
    }
    &.newStockAmount {
      padding: 0 20px;
    }
  }
}
.changeCapital {
  width: 350px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    border-bottom: 1px solid #d8d8d8;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    &:last-child {
      border-bottom: none;
    }
    .light {
      color: #555;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: -0.16px;
    }
    .important {
      color: #555;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: -0.6px;
    }
  }
}
