.explainBox {
  .explain {
    background-color: #ecf0f8;
    padding: 8px;
    margin-top: 10px;
    position: relative;
    border-radius: 4px;
  }
  .inImageBtn {
    position: absolute;
    bottom: 48px;
    left: 92px;
  }
  .explain:nth-child(1) {
    margin-top: 0;
  }
}
.advertisementBox {
  margin-top: 30px;
  padding: 24px 23px;
  width: 560px;
  border: 1px solid #d3dae2;
  border-radius: 4px;
  text-align: center;
  .title {
    color: #000;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.96px;
    .bold {
      font-weight: bold;
    }
  }
  .advertisement {
    margin-top: 14px;
    img {
      width: 514px;
      border-radius: 6px;
    }
  }
  .linkBtn {
    margin-top: 20px;
    display: block;
    padding: 18px 0 16px;
    width: 100%;
    height: 56px;
    background-color: #4c49e5;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.72px;
    text-decoration: none;
  }
  .explain {
    margin-top: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.64px;
  }
}
.radioImportant {
  font-weight: 500;
}
.radioSub {
  font-size: 16px;
  color: #585a5f;
  position: absolute;
  top: 52px;
  left: 45px;
}
.radioTag {
  display: inline-block;
  position: absolute;
  top: 0;
  right: -107px;
  padding: 5px 0;
  width: 60px;
  height: 32px;
  background-color: #006ac3;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  letter-spacing: -0.48px;
  text-align: center;
}
.existingShareholders {
  .title {
    color: #000;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: -0.78px;
  }
  .table {
    margin-top: 24px;
    width: 100%;
    thead {
      tr {
        th {
          padding: 10px 0 11px;
          color: #000;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.48px;
          background-color: #f6f5f5;
          &:nth-child(1) {
            padding-left: 41px;
            padding-right: 6px;
            text-align: left;
          }
          &:nth-child(2) {
            padding-left: 37px;
            padding-right: 24px;
            text-align: left;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 14px 0;
          border-bottom: 1px solid #c6cede;
          color: #000;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: -0.54px;
          &:nth-child(1) {
            padding-left: 8px;
            padding-right: 6px;
            .index {
              display: inline-block;
              margin-right: 3px;
              width: 30px;
            }
          }
          &:nth-child(2) {
            padding-left: 37px;
            color: #006ac3;
            line-height: 26px;
            background-image: url('/images/ico_triangle_arrow.svg');
            background-repeat: no-repeat;
            background-position: center left 6px;
          }
        }
        &:last-child {
          td {
            border-bottom: 2px solid #999fac;
          }
        }
      }
    }
  }
}
