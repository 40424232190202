.explainImg {
  background-color: #ecf0f8;
  padding: 8px;
}
.selectTable {
  .tableBtnRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  table {
    width: 100%;
    margin-top: 16px;
    th {
      font-size: 18px;
      color: #fff;
      background-color: #999fac;
      height: 58px;
      font-weight: 400;
      padding: 0 20px;
      letter-spacing: -0.54px;
      line-height: 1.19;
    }
    tbody {
      tr:nth-last-child(1) {
        td {
          border-bottom: 2px solid #999fac;
        }
      }
    }
    td {
      padding: 0 20px;
      font-size: 18px;
      font-weight: 400;
      height: 59px;
      border-bottom: 1px solid #c6cede;
    }
  }
}
.questionList {
  li {
    display: flex;
    justify-content: space-between;
    padding-bottom: 18px;
    padding-top: 18px;
    border-bottom: 1px solid #d3d3d3;
    &:nth-child(1) {
      padding-top: 0;
    }
    &:nth-last-child(1) {
      padding-bottom: 0;
      border-bottom: none;
    }
    .question {
      display: inline-block;
      width: 95px;
      flex-shrink: 0;
      color: #404040;
      line-height: 1.29;
      font-size: 14px;
      letter-spacing: -0.6px;
    }
    .answer {
      margin-left: 15px;
      .title {
        display: inline-block;
        width: 100%;
        font-size: 15px;
        color: #006ac3;
        letter-spacing: -0.6px;
      }
      .explain {
        letter-spacing: -0.8px;
        margin-top: 8px;
        font-size: 14px;
        color: #404040;
        line-height: 1.29;
        position: relative;
        display: inline-block;
        padding-left: 25px;
        box-sizing: border-box;
        font-weight: 400;
        .arrow {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
.fileUpload {
  .bold {
    cursor: pointer;
    color: #006ac3;
    font-weight: 700;
  }
}
