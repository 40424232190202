.table {
  width: 789px;
  th {
    padding: 3px 10px;
    width: 130px;
    height: 26px;
    color: #000;
    font-size: 13px;
    letter-spacing: -0.13px;
    background-color: #f4f5f7;
    text-align: center;
    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }
  tr {
    margin-left: 20px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.13px;
    line-height: normal;
    text-align: center;
    border-bottom: 1px solid #c6cbd5;
    td {
      padding: 2px 10px;
      width: 130px;
      border-top: 1px solid #c6cbd5;
      &:first-child {
        padding-left: 24px;
        text-align: center;
      }
      &:last-child {
        padding-right: 24px;
        text-align: center;
      }
    }
  }
}
.changeCapital {
  width: 354px;
  li {
    display: flex;
    justify-content: space-between;
    padding: 3px 24px;
    border-top: 1px solid #c6cbd5;
    &:last-child {
      border-bottom: 1px solid #c6cbd5;
    }
    color: #000;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.13px;
  }
}
.freeIssueTable {
  margin-top: 10px;
  width: 550px;
  th {
    padding: 3px 10px;
    width: 130px;
    height: 26px;
    color: #000;
    font-size: 13px;
    letter-spacing: -0.13px;
    background-color: #f4f5f7;
    text-align: center;
    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }
  tbody {
    tr {
      margin-left: 20px;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: -0.13px;
      line-height: normal;
      text-align: center;
      border-bottom: 1px solid #c6cbd5;
      td {
        padding: 2px 10px;
        width: 130px;
        border-top: 1px solid #c6cbd5;
        &:first-child {
          padding-left: 24px;
          text-align: center;
        }
        &:last-child {
          padding-right: 24px;
          text-align: center;
        }
      }
    }
  }
  tfoot {
    tr {
      td {
        padding: 2px 10px;
        border-bottom: none;
        text-align: center;
      }
    }
  }
}
