@import '@pages/ModalContent/ModalContent.module.scss';

.Modal {
  .title {
    width: 420px;
  }
  .modalContent {
    width: 400px;
  }
}
