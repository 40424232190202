.addressBadge {
  margin-bottom: 18px;
  position: relative;
  width: 100%;
  height: 240px;
  background-color: #d3dae2;
  .addressBadgeImg {
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
