.textWrap {
  display: flex;
  align-items: center;
  .text {
    color: #707070;
    line-height: 1.2;
    letter-spacing: -0.3px;
  }
  .bold {
    color: #006ac3;
    line-height: 1.33;
    letter-spacing: -0.3px;
  }
  .arrow {
    margin: 0 4px;
  }
}

.orderList {
  position: relative;
  padding-left: 19px;
  color: #2e2e2e;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.41;
  letter-spacing: -0.34px;
  counter-reset: number 0;
  li {
    margin-top: 8px;
    &:before {
      counter-increment: number 1;
      content: counter(number) '.';
      display: inline-block;
      position: absolute;
      left: 0;
    }
    .likeBtn {
      margin-left: -15px;
      margin-top: 8px;
    }
  }
}

.textBox {
  color: #6c6c6c;
  line-height: 1.43;
  letter-spacing: -0.3px;
}

.balanceBadge,
.ledgerBadge {
  margin-bottom: 10px;
  width: 100%;
  background-color: #ecf0f8;
  &.balanceBadge {
    height: 570px;
  }
  &.ledgerBadge {
    height: 701px;
  }
  .balanceBadgeImg,
  .ledgerBadgeImg {
    display: inline-block;
    position: relative;
    top: 8px;
    left: 8px;
  }
  .balanceBadgeText {
    margin-top: 19px;
    padding: 0 22px;
    ul {
      li {
        position: relative;
        padding-left: 19px;
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: -0.8px;
        .bold {
          color: #006ac3;
          font-weight: 500;
        }
        &:before {
          content: '※';
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

.fileUpload {
  .bold {
    cursor: pointer;
    color: #006ac3;
    font-weight: 700;
  }
}

.highLight {
  display: inline-block;
  padding: 0 8px;
  color: #006ac3;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -1.68px;
  text-align: center;
  background-color: #e0edf8;
  border-radius: 4px;
}

.freeIssueOfNewSharesInfo {
  position: relative;
  width: 100%;
  height: 654px;
  background-color: #ecf0f8;
  border-radius: 4px;
  .freeIssueOfNewSharesInfoImg {
    position: absolute;
    top: 8px;
    left: 8px;
  }
}

.financialStatementsBadge {
  position: relative;
  width: 100%;
  height: 722px;
  background-color: #ecf0f8;
  .financialStatementsBadgeImg {
    position: absolute;
    top: 8px;
    left: 8px;
  }
}

.financialStatementsExceptionBadge {
  position: relative;
  width: 100%;
  height: 116px;
  background-color: #ecf0f8;
  border-radius: 4px;
  .financialStatementsExceptionBadgeImg {
    position: absolute;
    top: 8px;
    left: 8px;
  }
}

.AOABox,
.stockholdersListInfoBox,
.stockholdersFileInfoBox,
.stockholdersListBox,
.LimitStockholdersListInfoBox,
.LimitStockholdersFileInfoBox,
.LimitStockholdersListBox {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  background-color: #ecf0f8;
  border-radius: 4px;
  &.AOABox {
    height: 138px;
  }
  &.stockholdersListInfoBox,
  &.LimitStockholdersListInfoBox {
    height: 199px;
  }
  &.stockholdersFileInfoBox,
  &.LimitStockholdersFileInfoBox {
    height: 158px;
  }
  &.stockholdersListBox {
    height: 365px;
  }
  &.LimitStockholdersListBox {
    height: 356px;
  }
  .AOABoxImg,
  .stockholdersListInfoImg,
  .stockholdersFileInfoImg,
  .stockholdersListBoxImg,
  .LimitStockholdersListInfoImg,
  .LimitStockholdersFileInfoImg,
  .LimitStockholdersListBoxImg {
    position: absolute;
    top: 8px;
    left: 8px;
  }
}

.financialStatementBadge,
.financialListBadge {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  background-color: #ecf0f8;
  &.financialStatementBadge {
    height: 500px;
  }
  &.financialListBadge {
    height: 402px;
  }
  .financialStatementBadgeImg,
  .financialListBadgeImg {
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
