.standByUpdate {
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 1240px;
  height: 231px;
  padding-left: 45px;
  padding-top: 100px;
  margin: 0 auto;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px 0 rgba(81, 96, 160, 0.15);
  color: #000;
  font-size: 32px;
  font-weight: 500;
  line-height: 0.84;
  letter-spacing: -1.28px;
  .bold {
    color: #2f2cc1;
  }
  background-image: url('/images/standbyInfo.png');
  background-repeat: no-repeat;
  background-position: right 34px bottom 0;
}
.radioLabelExplain {
  color: #585a5f;
  font-size: 16px;
  letter-spacing: -0.4px;
}
