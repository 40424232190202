.branchOfficeChange {
  font-weight: 500;
  line-height: 1.3;
  .changeInfoBox {
    display: block;
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
    .changeBranchInfo {
      display: flex;
      .dName {
        width: 140px;
        color: #555;
        font-weight: 400;
      }
    }
    .changeInfo {
      display: flex;
      margin-top: 3px;
      .dName {
        width: 140px;
        color: #555;
        font-weight: 400;
      }
      .data {
        .changeInfoDetail {
          display: flex;
          margin-top: 3px;
          &:first-child {
            margin-top: 0;
          }
          .newBranch,
          .managerChange {
            width: 130px;
          }
          .newBranchName,
          .managerChangeInfo {
            margin-left: 30px;
            font-size: 13px;
            &.managerChangeInfo {
              line-height: 1.4;
            }
          }
        }
        .addressCopyBtn {
          margin-left: 8px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
