.won {
  display: inline-block;
  position: relative;
  top: 17px;
  margin-left: 12px;
  vertical-align: top;
  color: #000;
  font-size: 20px;
  font-weight: 500;
}
