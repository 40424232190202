.container {
  display: flex;
  flex-direction: column;

  background-color: white;

  border-radius: 8px 0 0 8px;
  border: 1px solid #d3dae2;
  border-right: none;
  background: white;

  min-width: 560px;
  height: 720px;

  flex: 0 1;

  padding: 24px 0 0;
}
