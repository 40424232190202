.container {
  flex: 1;

  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;

  margin-top: 16px;

  .header {
    margin: 12px 24px;

    color: #777c86;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -1.25px;

    span {
      color: #2e2e2e;
    }

    .divider {
      margin-top: 8px;
      border-top: 1px solid #c6cbd5;
      width: 100%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    height: 100%;
    overflow-y: scroll;

    padding: 0 24px 12px;
  }

  .wrap {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .footer {
    .direct {
      border-top: 1px solid #d8d8d8;
      padding: 16px 0 12px;
      margin: 0 24px;

      display: flex;
      flex-direction: column;
      gap: 4px;

      color: #777c86;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: -0.88px;

      .checkWrap {
        display: flex;
        align-items: center;
        gap: 12px;

        .label {
          font-size: 18px;
          line-height: 35px;
          letter-spacing: -1.25px;

          span {
            color: #2e2e2e;
          }
        }
      }
    }

    button {
      border-radius: 0 0 0 8px;

      width: 100%;
      height: 58px;
      background: #006ac3;

      color: #fff;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 29px; /* 145% */
      letter-spacing: -0.48px;
    }
  }
}
