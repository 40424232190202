.branchOfficeChange {
  line-height: 1.3;
  .changeInfoBox {
    display: block;
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
    .changeBranchInfo,
    .changeInfo {
      display: flex;
      margin-top: 20px;
      &.changeBranchInfo {
        &:first-child {
          margin-top: 0;
        }
      }
      .dName {
        width: 148px;
        color: #555;
        font-weight: 400;
      }
      .data {
        .changeInfoDetail {
          display: flex;
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
          .newBranch,
          .managerChange {
            width: 177px;
          }
          .newBranchName,
          .managerChangeInfo {
            margin-left: 30px;
            color: #555;
            font-size: 18px;
            &.managerChangeInfo {
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
}
