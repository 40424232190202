@import '@pages/QuestionHeader.module.scss';

.agriculturalFishery {
  .fileUpload {
    .bold {
      cursor: pointer;
      color: #006ac3;
      font-weight: 700;
    }
  }
  .aLink {
    display: inline-block;
    margin-top: 10px;
    width: 224px;
    height: 62px;
    background-image: url('/images/efamily_link.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
}
