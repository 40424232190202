@import '@pages/ModalContent/ModalContent.module.scss';

.Modal {
  .title {
    font-weight: 500;
    letter-spacing: -0.96px;
    .required {
      color: #22aae0;
    }
    .unRequired {
      color: #0fac88;
    }
  }
  .modalContent {
    width: 437px;
  }
  .termsBox {
    overflow-y: scroll;
    margin-top: 18px;
    width: 437px;
    height: 426px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    &.termsRequired {
      //이용약관동의_필수
      .terms {
        font-size: 18px;
        font-weight: 400;
      }
      :global .content {
        margin-top: 20px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.35px;
        :global .termsTitle {
          font-weight: 400;
        }
        :global .termsText {
          font-weight: 300;
          margin-top: 4px;
        }
      }
    }

    &.termsUnRequired {
      //이용약관동의_선택
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.35px;
      :global .subTitle {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4;
        &:nth-child(2) {
          margin-top: 10px;
        }
      }
      :global .contentBox {
        font-size: 14px;
        letter-spacing: -0.35px;
        :global .content {
          margin-top: 20px;
          :global .termsTitle {
            font-weight: 400;
          }
          :global .termsText {
            font-weight: 300;
            margin-top: 4px;
          }
        }
      }
    }
  }
}
