.badge {
  margin-bottom: 18px;
  position: relative;
  width: 100%;
  height: 240px;
  background-color: #d3dae2;
  .badgeImg {
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
.orderList {
  position: relative;
  color: #6c6c6c;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.88px;
  line-height: 1.4;
  .bold {
    color: #404040;
    font-weight: 500;
  }
  .first {
    padding-left: 20px;
    &:before {
      content: '1.';
      display: inline-block;
      position: absolute;
      left: 0;
      color: #404040;
      font-weight: 500;
    }
  }
  .second {
    margin-top: 16px;
    padding-left: 20px;
    &:before {
      content: '2.';
      display: inline-block;
      position: absolute;
      left: 0;
      color: #404040;
      font-weight: 500;
    }
  }
}
