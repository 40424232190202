.selectWrap > .select[data-error='error'] {
  margin-bottom: 27px;
  border: 2px solid #eb4d59;
  border-radius: 2px;
  background-color: rgba(235, 77, 89, 0.1);
}

.selectWrap > .select[data-error='error'].errorFixed {
  margin-bottom: 0;
}

.selectWrap > .select[data-error='error'] > option {
  background-color: #fff;
}
