.titleWrap {
  .title {
    color: #2e2e2e;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: -1.04px;
    line-height: 1.46;
    .bold {
      color: #006ac3;
    }
  }
}

.executiveMemberChange {
  margin-top: 24px;
  width: 560px;
  border-bottom: 1px solid #3e3e3e;
  .header {
    display: flex;
    .exist,
    .new {
      justify-content: center;
      align-items: center;
      height: 50px;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.54px;
      line-height: 50px;
      text-align: center;
      &.exist {
        width: 230px;
        background-color: #999fac;
      }
      &.new {
        width: 330px;
        background-color: #3e3e3e;
      }
    }
  }
  .info {
    .infoRow {
      display: flex;
      min-height: 96px;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.6px;
      &.even {
        background-color: #fff;
      }
      &.odd {
        background-color: #f6f5f5;
      }
      .originInfo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 13px;
        width: 230px;
        color: #808796;
        line-height: 1.35;
        text-align: center;
        &.long {
          font-size: 17px;
          .englishName {
            font-size: 15px;
          }
        }
        .arrow {
          position: absolute;
          right: -8px;
        }
        .englishName {
          font-weight: 400;
        }
      }
      .newInfo {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 10px 23px 10px 42px;
        width: 330px;
        color: #000;
        .changeTypeAndBasicInfo {
          display: flex;
          align-items: center;
          .changeType {
            .blue,
            .red,
            .green,
            .gray {
              margin-top: 4px;
              width: 88px;
              height: 32px;
              border-radius: 4px;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: -0.48px;
              text-align: center;
              line-height: 29px;
              &.blue {
                border: 1px solid #006ac3;
                background-color: #e5f0f9;
                color: #006ac3;
              }
              &.red {
                border: 1px solid #eb4d59;
                background-color: #fdedee;
                color: #eb4d59;
              }
              &.green {
                border: 1px solid #0fac88;
                background-color: #e6f7f3;
                color: #0fac88;
              }
              &.gray {
                border: 1px solid #808796;
                background-color: #eeeeef;
                color: #808796;
              }
              &:first-child {
                margin-top: 0;
              }
            }
          }
          .basicInfo {
            margin-left: 10px;
            color: #000;
            font-size: 20px;
            font-weight: 500;
            line-height: 1.18;
            letter-spacing: -0.51px;
            &.retire {
              color: #eb4d59;
              text-decoration-line: line-through;
            }
            &.noChange {
              color: #808796;
            }
            &.long {
              font-size: 17px;
            }
          }
        }
        .changePosition {
          display: inline-flex;
          margin-top: 3px;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.48px;
          .origin {
            color: #808796;
          }
          .new {
            color: #006ac3;
            font-weight: 500;
          }
          .lightArrow {
            margin: 0 3px;
          }
        }
        .description {
          margin-top: 3px;
          color: #808796;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.2;
          letter-spacing: -0.48px;
          .positionOnRegister {
            color: #000;
            font-weight: 500;
          }
          .lightArrow {
            padding: 0 4px;
            position: relative;
            top: 4px;
          }
        }
      }
    }
  }
}
