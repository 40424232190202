.textarea {
  border: 1px solid #c6cbd5;
  width: 100%;
  resize: none;
  box-sizing: border-box;
  height: 420px;
  padding: 15px 20px;
  margin-top: 20px;
  line-height: 1.5;
  font-size: 16px;
  letter-spacing: -0.67px;
  color: #000;
}

.fileUpload {
  .bold {
    cursor: pointer;
    color: #006ac3;
    font-weight: 700;
  }
}

.helpMeStockOption {
  text-decoration: underline;
}
