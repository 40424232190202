.originBranchData {
  .originBranchAddress {
    margin-top: 6px;
    &:first-child {
      margin-top: 0;
    }
  }
}

.newBranchData {
  .newBranch {
    position: relative;
    margin-top: 6px;
    padding-left: 14px;
    .newBranchNum {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 3px;
    }
    &:first-child {
      margin-top: 0;
    }
  }
}
