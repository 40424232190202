.regulation {
  margin-top: 6px;
  padding: 4px 6px;
  background-color: #e9ecf0;
  border-radius: 4px;
  color: #555;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.2px;
  text-align: center;
}
