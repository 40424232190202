.reviseBtn {
  display: block;
  margin-top: 12px;
  width: 140px;
  height: 40px;
  border: 2px solid #006ac3;
  background-color: transparent;
  color: #006ac3;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.96px;
  > span {
    padding-right: 11px;
    background-image: url('/images/ico_arrow.svg');
    background-repeat: no-repeat;
    background-position: right top 7px;
  }
}
.select {
  &:disabled {
    border: 1px solid #c6cbd5;
    background-color: #f1f2f5;
    opacity: 1 !important;
    background-image: url('/images/ico_select_disabled.svg');
  }
}
.CompanyExecutiveBadge {
  margin-bottom: 24px;
  position: relative;
  width: 100%;
  height: 233px;
  background-color: #d3dae2;
  .CompanyExecutiveBadgeImg {
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
.compoWrap {
  display: inline-block;
  position: relative;
  .inputText {
    margin-right: 0 !important;
  }
  .closeBtn {
    display: inline-block;
    position: absolute;
    top: 11px;
    right: -39px;
  }
}
.representativeAddRowWrap {
  margin-top: 8px !important;
}
.representativeAddBtn {
  width: 100%;
  height: 58px;
  background-color: transparent;
  border: 1px dashed #999fac;
  border-radius: 4px;
  color: #999fac;
  font-size: 16px;
  font-weight: 500;
  .addGray {
    position: relative;
    top: -2px;
    width: 19px;
    height: 19px;
    margin-left: 5px;
    vertical-align: middle;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #f1f2f5;
  }
}
.FundInvestmentBadge {
  margin-bottom: 24px;
  position: relative;
  width: 100%;
  height: 355px;
  background-color: #d3dae2;
  .FundInvestmentBadgeImg {
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
.representativeRowWrap {
  position: relative;
  .representativeCloseBtn {
    position: absolute;
    right: -11px;
    top: 11px;
  }
}
