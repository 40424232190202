.liquidationInfo,
.executiveInfo {
  display: flex;
  margin-top: 3px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.2px;
  &:first-child {
    margin-top: 0;
  }
  .liquidationNamePosition,
  .executiveNamePosition {
    width: 100px;
  }
  .liquidationDetail,
  .executiveDetail {
    margin-left: 8px;
    letter-spacing: -0.18px;
  }
}
