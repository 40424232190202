.CompanyExecutiveBadge {
  margin-bottom: 24px;
  position: relative;
  width: 100%;
  height: 233px;
  background-color: #d3dae2;
  .CompanyExecutiveBadgeImg {
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
.compoWrap {
  display: inline-block;
  position: relative;
  .inputText {
    margin-right: 0 !important;
  }
  .closeBtn {
    display: inline-block;
    position: absolute;
    top: 11px;
    right: -39px;
  }
}
.representativeAddRowWrap {
  margin-top: 8px !important;
}
.representativeAddBtn {
  width: 100%;
  height: 58px;
  background-color: transparent;
  border: 1px dashed #999fac;
  border-radius: 4px;
  color: #999fac;
  font-size: 16px;
  font-weight: 500;
  .addGray {
    position: relative;
    top: -2px;
    width: 19px;
    height: 19px;
    margin-left: 5px;
    vertical-align: middle;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #f1f2f5;
  }
}
.FundInvestmentBadge {
  margin-bottom: 24px;
  position: relative;
  width: 100%;
  height: 355px;
  background-color: #d3dae2;
  .FundInvestmentBadgeImg {
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
.representativeRowWrap {
  position: relative;
  .representativeCloseBtn {
    position: absolute;
    right: -11px;
    top: 11px;
  }
}
