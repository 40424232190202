.Modal {
  width: 1180px;
  max-width: 90vw;
  max-height: 90vh;

  .title {
    color: #1e1e22;
    font-family: 'Noto Sans KR';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 24px */

    margin-bottom: 24px;
  }
  .modalContentWrapper {
    overflow-y: scroll;
    max-height: 40vh;

    table {
      border-collapse: collapse;
      width: 100%;
      color: #353333;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px; /* 144.444% */
      letter-spacing: -1px;
      th,
      td {
        text-align: left;
        padding: 8px;
        padding: 16px;
      }
      th {
        background: #f6f5f5;

        color: #353333;
        font-family: 'Noto Sans KR';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 144.444% */
        letter-spacing: -1px;
      }
      td {
        border-bottom: 1px solid #c6cbd5;

        color: #585a5f;
        font-family: 'Noto Sans KR';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -1px;
      }
      .purpose {
        width: 190px;
        color: #404040;
        font-size: 18px;
        font-weight: 500;
      }
      .minCapital {
        width: 200px;
        font-weight: 500;
      }
      .registerCaution {
        width: 390px;
        white-space: pre-wrap;
        word-break: break-all;
      }
      .noticeNecessary {
        width: 244px;
      }
    }
  }

  .guide {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;

    .strong {
      color: #2e2e2e;
      font-family: 'Noto Sans KR';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 30px */
      letter-spacing: -1px;
    }

    .text {
      color: #585a5f;
      font-family: 'Noto Sans KR';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -1px;
    }
  }

  .next {
    display: flex;
    width: 424px;
    height: fit-content;
    padding: 13px 0px 15px 0px;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    background: #006ac3;

    margin: 48px auto 0;

    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
  }
}
