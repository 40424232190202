.infoTable {
  width: 100%;
  tr {
    th {
      background-color: #e5f0f9;
      text-align: center;
      font-weight: 400;
    }
    td {
      text-align: left;
      &.empty {
        color: #777c86;
        font-weight: 400;
      }
      font-weight: 500;
    }
    td,
    th {
      border-bottom: 1px solid #c6cbd5;
      border-left: 1px solid #c6cbd5;
      border-right: 1px solid #c6cbd5;
      font-size: 20px;
      letter-spacing: -0.5px;
      color: #000;
      padding: 18px 20px;
      line-height: 29px;
    }
    &:first-child {
      td,
      th {
        border-top: 1px solid #c6cbd5;
      }
    }
  }
}
.placeholder {
  :global .text {
    &::placeholder {
      font-size: 18px !important;
    }
  }
}

.textBoxWrap {
  .textBoxExplain {
    margin-top: 5px;
    color: #6c6c6c;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.88px;
    line-height: 1.4;
  }
  & + .answer {
    margin-top: 18px !important;
  }
}
