.table {
  width: 100%;
  color: #555;
  font-weight: 300;
  th {
    height: 38px;
    background-color: #f6f5f5;
    font-size: 17px;
    font-weight: 400;
    &:nth-child(1) {
      padding-right: 50px;
    }
    &:nth-child(2) {
      padding-left: 50px;
      padding-right: 60px;
    }
    &:nth-child(3) {
      padding-left: 60px;
      padding-right: 60px;
    }
    &:last-child {
      padding-left: 60px;
      padding-right: 15px;
    }
  }
  td {
    font-size: 15px;
    line-height: 1.5;
    padding: 10px 0;
    border-bottom: 1px solid #d8d8d8;
    .name {
      color: #000;
      font-size: 20px;
      font-weight: 500;
      .englishName {
        font-size: 13px;
        color: #555;
      }
    }
    &:nth-child(1) {
      vertical-align: top;
      padding-right: 50px;
    }
    &:nth-child(2) {
      padding-left: 50px;
      padding-right: 60px;
    }
    &:nth-child(3) {
      padding-left: 60px;
      padding-right: 60px;
    }
    &:last-child {
      padding-left: 60px;
      padding-right: 15px;
    }
  }
}
