.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 24px;

  .title {
    color: #2e2e2e;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px; /* 100% */
  }

  .explain {
    color: #777c86;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.88px;
  }

  .searchWrapper {
    flex: 1;

    .input {
      width: 397px;
      height: 48px;
      padding: 0 15px;
      border: 1px solid #ddd;
      border-radius: 2px;
      font-size: 15px;

      &::placeholder {
        color: #999;
      }
    }

    .button {
      width: 115px;
      height: 48px;
      border: none;
      border-radius: 2px;
      margin-left: 8px;
      background-color: #006ac3;
      color: white;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
    }
  }
}
