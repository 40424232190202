.explainBox {
  .explain {
    background-color: #ecf0f8;
    padding: 8px;
    margin-top: 8px;
    position: relative;
    border-radius: 4px;
  }
  .inImageBtn {
    position: absolute;
    bottom: 48px;
    left: 92px;
  }
  .explain:nth-child(1) {
    margin-top: 0;
  }
}
.radioImportant {
  font-weight: 500;
}
.radioSub {
  font-size: 16px;
  color: #585a5f;
  position: absolute;
  top: 50px;
  left: 45px;
}
.fileUpload {
  .bold {
    cursor: pointer;
    color: #006ac3;
    font-weight: 700;
  }
}
.animationBox {
  margin-top: 30px;
  padding: 24px 23px;
  width: 560px;
  border: 1px solid #d3dae2;
  border-radius: 4px;
  text-align: center;
  .animationTitle {
    color: #000;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.96px;
    .bold {
      font-weight: 500;
    }
  }
  .animation {
    margin-top: 14px;
    img {
      width: 514px;
      border-radius: 6px;
    }
  }
  .linkBtn {
    margin-top: 20px;
    display: block;
    padding: 18px 0 16px;
    width: 100%;
    height: 56px;
    background-color: #4c49e5;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.72px;
    text-decoration: none;
  }
  .explain {
    margin-top: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.64px;
  }
}
