.stampWrap {
  .name {
    color: #000;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.96px;
    & + .stamp {
      margin-top: 13px;
    }
  }
  .stamp {
    .stampLabel {
      position: relative;
      cursor: pointer;
      display: inline-block;
      margin-left: 8px;
      width: 276px;
      height: 254px;
      &:first-child {
        margin-left: 0;
      }
      .badgeImg {
        //ie label
        pointer-events: none;
      }
      .radio {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -10000;
        opacity: 0;
        width: 0;
        height: 0;
        &:checked + span {
          color: #fd6200;
        }
      }
      .free,
      .addPrice {
        display: inline-block;
        width: 100%;
        padding: 2px 0;
        color: #000;
        font-size: 20px;
        font-weight: 500;
        line-height: 50px;
        letter-spacing: -0.8px;
        text-align: center;
        &.active {
          color: #006ac3;
        }
      }
    }
  }
  & + .stampWrap {
    margin-top: 30px;
  }
}
.corporateSealsSign {
  display: flex;
  align-items: center;
  margin-top: 18px;
  .executiveName {
    display: inline-block;
    margin-right: 16px;
    width: 210px;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 1.2;
  }
}
.addStamp {
  width: 100%;
  border-top: 2px solid #999fac;
  border-bottom: 2px solid #999fac;
  .addStampWrap {
    display: flex;
    align-items: flex-start;
    padding: 18px 0;
    &:first-child {
      border-bottom: 1px solid #c6cede;
    }

    .stampImg {
      .basicStamp,
      .premiumStamp {
        width: 122px;
        height: 89px;
        border-radius: 4px;
      }
    }
    .stampInfo {
      margin-left: 16px;
      width: 100%;
      .stamp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-height: 89px;
        .stampeName {
          color: #000;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: -0.8px;
        }
        .stampNamePrice {
          color: #006ac3;
          font-size: 15px;
          font-weight: 500;
          .stampPrice {
            margin-top: 7px;
            .won {
              position: relative;
              top: -1px;
              margin-right: 7px;
              vertical-align: middle;
            }
          }
        }
        .stampeTotal {
          width: 114px;
          color: #006ac3;
          font-size: 26px;
          font-weight: 500;
          text-align: right;
          .totalText {
            position: relative;
            left: 1px;
            bottom: 4px;
            font-size: 14px;
          }
        }
      }
      .usageSealSign {
        .usageSealSignExplain {
          color: #777c86;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.8px;
        }
        .sign {
          display: flex;
          align-items: center;
          margin-top: 18px;
          &:nth-child(2) {
            margin-top: 12px;
          }
          .signTitle {
            color: #000;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: -0.54px;
            line-height: 1.4;
            .signType {
              color: #777c86;
              font-weight: 400;
            }
          }
          .usageSealSignSelect {
            margin-left: 12px;
          }
        }
      }
    }
  }
}
.multiSignBadge {
  margin: 0 auto 25px;
  position: relative;
  width: 334px;
  height: 208px;
  background-color: #e5e5e5;
  .multiSignBadgeImg {
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
.signBadge {
  margin-bottom: 18px;
  position: relative;
  width: 276px;
  height: 209px;
  background-color: #e5e5e5;
  .signBadgeImg {
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
