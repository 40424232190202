.regulation {
  margin-top: 9px;
  padding: 14px 20px;
  background-color: #e9ecf0;
  border-radius: 4px;
  color: #555;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.2px;
}
