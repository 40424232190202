.agreeBox {
  width: 100%;
  border: 1px solid #c6cbd5;
  border-radius: 4px;
  &.allActive {
    outline: 1px solid #006ac3;
    border: 1px solid #006ac3;
  }
  .allCheck {
    padding: 27px 30px;
    &.active {
      background-color: rgba(0, 106, 195, 0.1);
    }
  }
  .checkList {
    li {
      position: relative;
      border-top: 1px solid #c6cbd5;
      .check {
        padding: 27px 30px;
        &.active {
          background-color: rgba(0, 106, 195, 0.1);
        }
      }
      .hyper {
        position: absolute;
        right: 30px;
        top: 33px;
      }
    }
  }
}
